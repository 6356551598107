<template>
  <div>
    <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <ToggleInput
            v-model="local.is_single_tenant"
            grow
            :disabled="leaseFormEditInd !== -1"
            :items="[
              { value: true, text: 'Single Tenant' },
              { value: false, text: 'Multiple Tenant' }
            ]"
            @input="handleTenantCountChange"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <ToggleInput
            v-model="local.is_add_lease"
            grow
            :disabled="leaseFormEditInd !== -1"
            :items="[
              { value: false, text: 'Skip the lease' },
              { value: true, text: 'Add the lease' }
            ]"
            @input="handleAddLeaseChange"
          />
        </v-col>
      </v-row>

      <div v-if="local.is_add_lease" class="mt-3">
        <div v-if="local.leases.length === 0 && leaseFormEditInd === -1" class="mb-n2">
          <v-alert
            border="left"
            text
            dense
            type="warning"
          >
            <div>There are no lease added yet</div>
          </v-alert>
        </div>
        <div v-if="validAddresses.length === 0" class="mb-n2">
          <v-alert
            border="left"
            text
            dense
            type="warning"
          >
            <div>Address is not selected</div>
          </v-alert>
        </div>
        <div v-else-if="leaseFormEditInd === -1">
          <v-card v-for="(item, i) in local.leases" :key="i" outlined class="d-flex align-center gap-md pl-4 pr-2 py-1 mb-2">
            <div>
              <v-list-item-title>
                ${{ item.commencing_rent | number }} ({{ dropdownItemName(item.commencing_rent_type_id, 'leaseAmountTypes') }}) | {{ item.lease_dt }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="isPartial">
                  {{ item.address_label_prefix }} {{ item.address_label_id }} - {{ item.address_label }}
                </div>
                <div v-else>
                  {{ item.address_label }}
                </div>
              </v-list-item-subtitle>
            </div>
            <v-list-item-action class="flex-row align-center gap-sm ml-auto">
              <v-btn icon @click=handleEditLease(i)>
                <v-icon small>$mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="handleDeleteLease(i)">
                <v-icon small>$mdi-trash-can</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-card>
        </div>
        <v-card v-if="leaseFormEditInd !== -1" outlined>
          <v-card-text>
            <v-row v-if="local.is_multi_sale" dense class="mb-3">
              <v-col cols="12">
                <label for="full_address" class="caption">Lease Address</label>
                <v-select
                  id="full_address"
                  v-model="leaseForm.address_detail_pid"
                  :items="addresses"
                  item-text="full_address"
                  item-value="address_detail_pid"
                  :multiple="local.is_multi_sale"
                  outlined
                  dense
                  hide-details
                  @change="handleSelectAddress"
                >
                </v-select>
              </v-col>
            </v-row>
            <div v-if="isPartial">Lease Address Label</div>
            <v-row dense class="mb-3">
              <template v-if="isPartial">
                <v-col cols="3">
                  <label for="address_label_prefix" class="caption">Prefix</label>
                  <DropdownSelect
                    id="address_label_prefix"
                    v-model="leaseForm.address_label_prefix"
                    ky="addressPrefixes"
                    item-value="name"
                    :clearable="false"
                    hide-details
                  />
                </v-col>
                <v-col cols="3">
                  <label for="address_label_id" class="caption">Unique ID</label>
                  <v-text-field
                    id="address_label_id"
                    v-model="leaseForm.address_label_id"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </template>
              <v-col>
                <label for="address_label" class="caption">Lease Address Label</label>
                <v-text-field
                  id="address_label"
                  v-model="leaseForm.address_label"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <LeaseForm
              v-model="leaseForm"
              ref="leaseForm"
              :is-sales-lease="false"
              :is-multi-sale="local.is_multi_sale"
              :is-single-tenant="local.is_single_tenant"
            />
            <div class="d-flex align-center mt-2">
              <v-btn v-if="local.leases.length !== leaseFormEditInd" text color="error" @click="handleDeleteLease">
                <v-icon left>$mdi-trash-can</v-icon>
                Delete
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="handleCancelLeaseForm">Cancel</v-btn>
              <v-btn color="primary" depressed class="ml-2" @click="handleSaveLeaseForm">Save</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <div
          v-if="
            leaseFormEditInd === -1 &&
            (local.is_single_tenant && local.leases.length < 1 || !local.is_single_tenant)
          "
          class="text-center mt-1"
        >
          <v-btn
            depressed
            :disabled="validAddresses.length === 0"
            @click="handleAddMoreLease"
          ><v-icon left>$mdi-plus</v-icon>
            Add Lease
          </v-btn>
        </div>
      </div>

      <v-row v-if="!local.is_add_lease" dense class="mt-3">
        <v-col cols="7">
          <v-checkbox
            v-model="local.is_owner_occupied"
            label="This is Owner Occupied"
            hide-details
            class="mt-0 pt-0"
          />
        </v-col>
        <v-col cols="7">
          <v-checkbox
            v-model="local.is_sublease"
            label="This is a Sub Lease"
            hide-details
            class="mt-0 pt-0"
          />
        </v-col>
      </v-row>

      <template v-if="!(local.is_single_tenant && local.is_add_lease) && leaseFormEditInd === -1">
        <ExpandPanel label="Income & Outgoings" class="mt-3" content-class="pl-3">
          <v-row dense>
            <v-col cols="8">
              <label for="expense_structure_id" class="caption">
                Expense Structure
              </label>
              <DropdownSelect
                id="expense_structure_id"
                v-model="local.expense_structure_id"
                ky="expenseStructures"
                :clearable="false"
                hide-details
              ></DropdownSelect>
            </v-col>
            <v-col cols="4">
              <label for="expense_structure_percent" class="caption">%</label>
              <CurrencyText
                id="expense_structure_percent"
                v-model="local.expense_structure_percent"
                :has-unit="false"
                suffix="%"
                :with-precision="false"
                hide-details
              />
            </v-col>
            <!-- TODO: Need to make this null if changed to other -->
            <v-col v-if="local.expense_structure_id === 5" cols="12">
              <label for="custom_expense_structure" class="caption">Custom Expense Structure</label>
              <v-text-field
                id="custom_expense_structure"
                v-model="local.custom_expense_structure"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-row dense class="mt-3">
            <v-col cols="6">
              <label for="recoverable_outgoings" class="caption">
                Recoverable Outgoings
              </label>
              <CurrencyText
                id="recoverable_outgoings"
                v-model="local.recoverable_outgoings"
                :has-unit="false"
                prefix="$"
                hide-details
              />
              <div class="d-flex ml-n1 mt-n1">
                <v-checkbox
                  v-model="local.is_recoverable_outgoings_approx"
                  label="Approx"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <label for="irrecoverable_outgoings" class="caption">
                Nonrecoverable Outgoings
              </label>
              <CurrencyText
                id="irrecoverable_outgoings"
                v-model="local.irrecoverable_outgoings"
                :has-unit="false"
                prefix="$"
                hide-details
              />
              <div class="d-flex ml-n1 mt-n1">
                <v-checkbox
                  v-model="local.is_irrecoverable_outgoings_approx"
                  label="Approx"
                  hide-details
                />
              </div>
            </v-col>
            <v-col cols="6">
              <label for="total_outgoings" class="caption">
                Total Outgoings
              </label>
              <CurrencyText
                id="total_outgoings"
                v-model="local.total_outgoings"
                :has-unit="false"
                prefix="$"
                hide-details
              />
              <div class="d-flex ml-n1 mt-n1">
                <v-checkbox
                  v-model="local.is_total_outgoings_approx"
                  label="Approx"
                  hide-details
                />
              </div>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="local.is_rla_applies"
                label="RLA Applies"
                hide-details
                class="ml-n1 pt-0"
              />
            </v-col>
          </v-row>
        </ExpandPanel>

        <ExpandPanel label="Add Tenant(s)" class="mt-3" content-class="pl-3 pt-2">
          <v-row dense>
            <v-col cols="12">
              <div>
                <label for="tenant_ids">Tenants</label>
                <v-autocomplete
                  id="tenant_ids"
                  v-model="local.tenant_ids"
                  :items="tenants"
                  :item-text="(item) => item.is_company ? `${item.company_name} (Company)` : `${item.full_name} (Individual)`"
                  item-value="id"
                  small-chips
                  chips
                  outlined
                  hide-details
                  multiple
                  clearable
                  prepend-inner-icon="$mdi-magnify"
                >
                </v-autocomplete>
                <div class="text-right">
                  <span
                    class="primary--text text-decoration-underline cursor-pointer caption"
                    @click="handleOpenContactModal('Tenant')"
                  >
                    Create a new tenant
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </ExpandPanel>
      </template>
    </v-form>

    <v-dialog
      v-model="contactModal"
      width="600"
      scrollable
      persistent
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
          Create New {{ contactForm.main_type }}
        </v-card-title>
        <v-card-text class="mt-3 px-4">
          <ContactForm
            ref="contactForm"
            v-model="contactForm"
            @close="contactModal = false"
            @completed="handleContactCreated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  defaultLeaseForm,
  defaultContactForm
} from '@/@propedia/default_forms'

import LeaseForm from '@/components/forms/LeaseForm.vue'
import ContactForm from '@/components/forms/ContactForm'

export default {
  components: {
    LeaseForm,
    ContactForm
  },

  props: [
    'value',
    'addresses',
    'isCompEdit'
  ],

  data() {
    return {
      valid: true,
      leaseFormEditInd: -1,
      leaseForm: structuredClone(defaultLeaseForm),
      contactModal: false,
      contactForm: structuredClone({
        main_type: null,
        ...defaultContactForm
      }),
      tenants: []
    }
  },

  computed: {
    ...mapGetters('dropdown', ['dropdownItemName']),
    ...mapState('contact', ['loadingContacts']),
    local() {
      return this.value
    },
    isPartial() {
      if (this.local.is_single_tenant) {
        return false
      }

      return true
    },
    isEdit() {
      return this.leaseFormEditInd !== -1 && this.local.leases.length !== this.leaseFormEditInd
    },
    validAddresses() {
      return this.addresses.filter((v) => !!v)
    }
  },

  mounted() {
    this._getContacts()
  },

  methods: {
    ...mapActions('contact', ['getContacts']),
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    handleTenantCountChange(v) {
      if (v) { // Multiple
        if (this.local.is_multi_sale) {
          // TODO Take care of use cases
        }
      } else { // Single, keep the first one and remove all
        const tenant_ids = [...this.local.tenant_ids]

        tenant_ids.splice(1)
  
        this.update('tenant_ids', tenant_ids)
      }
    },
    handleAddLeaseChange(v) {
      if (!v) {
        this.update('leases', [])
      }
    },
    handleAddMoreLease() {
      if (this.validAddresses.length) {
        this.leaseForm = this.local.is_multi_sale ? {
          ...defaultLeaseForm,
          address_detail_pid: [],
          address_label: null,
          address_label_prefix: null,
          address_label_id: null,
          is_partial: !this.local.is_single_tenant
        } : {
          ...defaultLeaseForm,
          address_detail_pid: this.validAddresses[0].address_detail_pid,
          address_label: this.validAddresses[0].address_label,
          address_label_prefix: null,
          address_label_id: null,
          is_partial: !this.local.is_single_tenant
        }

        this.leaseFormEditInd = this.local.leases.length
      }
    },
    handleEditLease(i) {
      this.leaseForm = structuredClone(this.local.leases[i])
      this.leaseFormEditInd = i
    },
    handleDeleteLease(i = null) {
      const leases = this.local.leases
      const index = i ?? this.leaseFormEditInd

      leases.splice(index, 1)
      this.update('leases', leases)

      this.leaseFormEditInd = -1
    },
    handleCancelLeaseForm() {
      this.leaseFormEditInd = -1
    },
    handleSaveLeaseForm() {
      if (this.$refs.leaseForm.$refs.form.validate()) {
        if (this.leaseFormEditInd === this.local.leases.length) { // Add new
          this.update('leases', [
            ...this.local.leases,
            this.leaseForm
          ])
        } else { // Update
          const leases = this.local.leases

          leases[this.leaseFormEditInd] = structuredClone(this.leaseForm)

          this.update('leases', leases)
        }

        this.leaseFormEditInd = -1
      }
    },
    handleSelectAddress() {
      if (this.leaseForm.address_detail_pid.length) {
        const address = this.addresses.find((v) => v.address_detail_pid === this.leaseForm.address_detail_pid[0])

        this.leaseForm.address_label = address.address_label
      } else {
        this.leaseForm.address_label_prefix = null
        this.leaseForm.address_label_id = null
        this.leaseForm.address_label = null
      }
    },
    async _getContacts() {
      this.tenants = await this.getContacts({
        main_types: ['Tenant']
      })
    },
    handleOpenContactModal(main_type) {
      this.contactForm = structuredClone({
        ...defaultContactForm,
        main_type
      }),
      this.contactModal = true
    },
    async handleContactCreated(id) {
      await this._getContacts()

      this.update('tenant_ids', [...this.local.tenant_ids, id])

      this.contactModal = false
    }
  }
}
</script>