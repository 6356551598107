import allLayers from '@/config/allLayers'
import mapSources from '@/config/sources'

export default {
  namespaced: true,

  state: {
    snackbar: {
      value: false,
      color: '',
      text: '',
      vertical: false
    },
    itemsPerPage: 10,
    adminDrawer: true,
    adminRightDrawer: false,
    listSearchDrawer: true
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar
    },
    SET_ADMINDRAWER(state, val) { state.adminDrawer = val },
    SET_ADMIN_RIGHT_DRAWER(state, val) { state.adminRightDrawer = val },
    SET_LIST_SEARCH_DRAWER(state, val) {
      state.listSearchDrawer = val
    }
  },

  actions: {
    toggleAdminDrawer: ({ state, commit }) => {
      commit('SET_ADMINDRAWER', !state.adminDrawer)
    },

    setAdminRightDrawer: ({ commit }, payload) => {
      commit('SET_ADMIN_RIGHT_DRAWER', payload)
    },

    toggleListSearchDrawer: ({ state, commit }) => {
      commit('SET_LIST_SEARCH_DRAWER', !state.listSearchDrawer)
    },

    hideSnackbar: ({ state, commit }) => {
      commit('SET_SNACKBAR', {
        ...state.snackbar,
        value: false
      })
    },
    notifySuccess: ({ commit }, message) => {
      commit('SET_SNACKBAR', {
        text: message,
        color: 'success',
        value: true
      })
    },
    notifyError: ({ commit }, message) => {
      commit('SET_SNACKBAR', {
        text: message,
        color: 'error',
        value: true
      })
    }
  },

  getters: {
    isAuth: (store) => {
      return store.token
    },
    fileIcon: () => (type) => {
      switch(type) {
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return '$mdi-file-word'
        case 'application/pdf':
          return '$mdi-file-pdf-box'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return '$mdi-file-table'
        case 'application/vnd.ms-outlook':
          return '$mdi-email'
        default:
          return '$mdi-file'
      }
    },
    // Get map layers depending on the page from all layers
    mapLayers: () => (page) => {
      if (!page) {
        return []
      }

      const layers = allLayers.filter((l) => {
        if (l.meta && l.meta.for) {
          return l.meta.for.includes(page)
        } else {
          return true
        }
      })

      return layers
    },
    // Get map style
    mapStyle: (_, getters) => (page) => {
      return {
        version: 8,
        name: "Propedia",
        bearing: 0,
        pitch: 0,
        sources: mapSources,
        glyphs: 'mapbox://fonts/landchecker/{fontstack}/{range}.pbf',
        layers: getters.mapLayers(page),
        owner: 'propedia',
        visibility: 'private'
      }
    }
  }
}