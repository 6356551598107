import api from '@/api'

export default {
  namespaced: true,

  state: {
    overlayCodes: [],
    zoneCodes: {
      vic: [],
      nsw: []
    },
    heritageCodes: [],
    landUseTypes: []
  },

  mutations: {
    SET_CODES(state, data) {
      state.zoneCodes.vic = data.zoneCodes
      state.overlayCodes = data.overlayCodes
      state.heritageCodes = data.heritageCodes
      state.landUseTypes = data.landUseTypes
    }
  },

  actions: {
    getCoverageCodes: (_, {
      type,
      address_detail_pids,
      gnaf_property_pids,
      state_abbreviation
    }) => {
      return api.get('/zoning_and_overlays/coverage_codes', {
        params: {
          type,
          address_detail_pids,
          gnaf_property_pids,
          state_abbreviation
        }
      })
    },
    getCodes: async ({ commit }) => {
      try {
        const response = await api.get('zoning_and_overlays/codes')

        commit('SET_CODES', response.data)
      } catch (_) {
        // do nothing
      }
    }
  },

  getters: {
    getZoneByCode: (state) => (type, code, state_abbreviation) => {
      if (type === 'zone') {
        if (state_abbreviation === 'VIC') {
          return state.zoneCodes.vic.find((c) => c.code === code)
        } else if (state_abbreviation === 'NSW') {
          return state.zoneCodes.nsw.find((c) => c.code === code)
        } else {
          return null
        }
      } else if (type === 'overlay') {
        return state.overlayCodes.find((c) => c.code === code)
      } else {
        return null
      }
    },
    zoneColor: (_, getters) => (type, code, state_abbreviation = 'VIC') => {
      const zone = getters.getZoneByCode(type, code, state_abbreviation)

      return zone?.fill_color
    },
    parentCodes: (state) => (type = 'zone', state_abbreviation = 'VIC') => {
      const stateLowerCased = state_abbreviation.toLowerCase()

      if (type === 'zone') {
        return [... new Set(state.zoneCodes[stateLowerCased].map((c) => c.parent))]
      } else if (type === 'overlay') {
        return [... new Set(state.overlayCodes.map((c) => c.parent))]
      } else {
        return []
      }
    },
    /**
     * Get parent-child code structure from child codes
     */
    structuredCodes: (state, getters) => (type = 'zone', state_abbreviation = 'VIC', childCodes = null) => {
      const stateLowerCased = state_abbreviation.toLowerCase()

      if (childCodes) {
        if (type === 'zone') {
          const zones = state.zoneCodes[stateLowerCased].filter((v) => childCodes.includes(v.code))
          const parentCodes = [... new Set(zones.map((c) => c.parent))]
          
          return parentCodes.map((p) => {
            const children = zones.filter((v) => v.parent === p)
    
            return {
              parent_code: p,
              fill_color: children.length ? children[0].fill_color : '',
              desc: children.length ? children[0].desc : '',
              children
            }
          })
        } else if (type === 'overlay') {
          const overlays = state.overlayCodes.filter((v) => childCodes.includes(v.code))
          const parentCodes = [... new Set(overlays.map((c) => c.parent))]

          return parentCodes.map((p) => {
            const children = overlays.filter((v) => v.parent === p)

            return {
              parent_code: p,
              fill_color: children.length ? children[0].fill_color : '',
              desc: children.length ? children[0].desc : '',
              children
            }
          })
        }
      } else {
        const parentCodes = getters.parentCodes(type, state_abbreviation)
  
        return parentCodes.map((p) => {
          let children = []
          
          if (type === 'zone') {
            children = state.zoneCodes[stateLowerCased].filter((v) => v.parent === p)
          } else if (type === 'overlay') {
            children = state.overlayCodes.filter((v) => v.parent === p)
          } else {
            children = []
          }
  
          return {
            parent_code: p,
            fill_color: children.length ? children[0].fill_color : '',
            children
          }
        })
      }
    }
  }
}