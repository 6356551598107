<template>
  <div>
    <div>
      <label class="caption">Zone</label>
      <ZoneOverlayMultiSelector
        :value="zones"
        :items="zoneCodeItems"
        :limited="true"
        @input="$emit('input-zones', $event)"
      />
    </div>

    <div class="mt-2">
      <label class="caption">Overlay</label>
      <ZoneOverlayMultiSelector
        :value="overlays"
        :items="overlayCodeItems"
        :limited="true"
        @input="$emit('input-overlays', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ZoneOverlayMultiSelector from '@/components/inputs/ZoneOverlayMultiSelector'

export default {
  props: ['zones', 'overlays'],

  components: {
    ZoneOverlayMultiSelector
  },
  
  computed: {
    ...mapState('zoning_and_overlay', [
      'zoneCodes',
      'overlayCodes'
    ]),
    zoneCodeItems() {
      return [
        { header: 'VIC' },
        { divider: true },
        ...this.zoneCodes.vic,
        { header: 'NSW' },
        { divider: true },
        ...this.zoneCodes.nsw,
      ]
    },
    overlayCodeItems() {
      return this.overlayCodes
    }
  }
}
</script>