export default [
  {
    "id": "background",
    "type": "background",
    "paint": {
      "background-color": "#e3e5e5"
    }
  },

  // landuse_park
  {
    "id": "landuse_park",
    "type": "fill",
    "source": "mapbox_streets",
    "source-layer": "landuse",
    "filter": ["==", "class", "park"],
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-color": "#99e1be"
    }
  },
  // waterway
  {
    "id": "waterway",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "waterway",
    "filter": ["all", ["==", "$type", "LineString"],
      ["in", "class", "canal", "river"]
    ],
    "paint": {
      "line-color": "#a0cfdf",
      "line-width": {
        "base": 1.4,
        "stops": [
          [8, 0.5],
          [20, 15]
        ]
      }
    }
  },
  // water
  {
    "id": "water",
    "type": "fill",
    "source": "mapbox_streets",
    "source-layer": "water",
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-color": "#a0cfdf"
    }
  },
  // waterway_light
  {
    "id": "waterway_light",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "waterway",
    "filter": ["all", ["==", "$type", "LineString"],
      ["in", "class", "canal", "river"]
    ],
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "line-color": "#cbcdcd",
      "line-width": {
        "base": 1.4,
        "stops": [
          [8, 0.5],
          [20, 15]
        ]
      },
      "line-opacity": 0
    }
  },
  // water_light
  {
    "id": "water_light",
    "type": "fill",
    "source": "mapbox_streets",
    "source-layer": "water",
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-color": "#cbcdcd",
      "fill-opacity": 0
    }
  },

  // zone and overlay
  {
    "id": "plan_overlay_vic",
    "type": "fill",
    "source": "plan_overlay_vic",
    "source-layer": "plan_overlay_vic",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "fill-color": ["get", "hex_color"],
      "fill-opacity": 0.6
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "plan_zone_vic",
    "type": "fill",
    "source": "plan_zone_vic",
    "source-layer": "plan_zone_vic",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "fill-color": ["get", "hex_color"],
      "fill-opacity": 0.6
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "plan_zone_nsw",
    "type": "fill",
    "source": "plan_zone_nsw",
    "source-layer": "plan_zone_nsw",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "fill-color": ["get", "fill_color"],
      "fill-opacity": 0.6
    },
    meta: {
      for: ['map_view']
    }
  },
  // Flood
  {
    id: "flood",
    type: "fill",
    source: "flood",
    "source-layer": "flood",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": '#1f78b4',
      "fill-opacity": 0.6
    },
    meta: {
      for: ['map_view']
    }
  },
  // Landslide
  {
    id: "landslide",
    type: "fill",
    source: "landslide",
    "source-layer": "landslide",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": '#ca2c92',
      "fill-opacity": 0.6
    },
    meta: {
      for: ['map_view']
    }
  },
  // tunnel_minor
  {
    "id": "tunnel_minor",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "tunnel"],
        ["in", "class", "motorway_link", "pedestrian", "service", "street", "street_limited", "tertiary"]
      ]
    ],
    "paint": {
      "line-color": "hsl(0, 0%, 74%)",
      "line-width": {
        "base": 1.55,
        "stops": [
          [4, 0.25],
          [20, 30]
        ]
      }
    }
  },
  // tunnel_major
  {
    "id": "tunnel_major",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "tunnel"],
        ["in", "class", "motorway", "primary", "secondary", "trunk"]
      ]
    ],
    "paint": {
      "line-color": "hsl(0, 0%, 74%)",
      "line-width": {
        "base": 1.4,
        "stops": [
          [6, 0.5],
          [20, 30]
        ]
      },
      "line-opacity": 0.58
    }
  },
  // major_rail
  {
    "id": "major_rail",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["==", "class", "major_rail"],
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "line-color": "hsl(0, 0%, 0%)",
      "line-opacity": {
        "base": 1.4,
        "stops": [
          [0, 0],
          [14, 0.1],
          [16, 0.2]
        ]
      },
      "line-width": 2
    }
  },
  // road_minor
  {
    "id": "road_minor",
    "type": "line",
    "metadata": {},
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["all", ["all", ["==", "structure", "none"],
        ["in", "class", "motorway_link", "path", "pedestrian", "service", "street", "street_limited", "tertiary"]
      ],
      ["in", "$type", "LineString", "Polygon"]
    ],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#efefef",
      "line-opacity": 0.8,
      "line-width": {
        "base": 1.55,
        "stops": [
          [4, 0.25],
          [20, 30]
        ]
      }
    }
  },
  // road_major
  {
    "id": "road_major",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "none"],
        ["in", "class", "motorway", "primary", "secondary", "trunk"]
      ]
    ],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#fff",
      "line-opacity": 0.8,
      "line-width": {
        "base": 1.4,
        "stops": [
          [6, 0.5],
          [20, 30]
        ]
      }
    }
  },
  // bridge_minor_case
  {
    id: "bridge_minor_case",
    type: "line",
    source: "mapbox_streets",
    "source-layer": "road",
    filter: ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "bridge"],
        ["in", "class", "motorway_link", "pedestrian", "service", "street", "street_limited", "tertiary"]
      ]
    ],
    paint: {
      "line-color": "#dedede",
      "line-width": {
        base: 1.6,
        stops: [
          [12, .5],
          [20, 10]
        ]
      },
      "line-gap-width": {
        base: 1.55,
        stops: [
          [4, .25],
          [20, 30]
        ]
      }
    }
  },
  // bridge_minor
  {
    "id": "bridge_minor",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "bridge"],
        ["in", "class", "motorway_link", "path", "pedestrian", "service", "street", "street_limited", "tertiary"]
      ]
    ],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#efefef",
      "line-width": {
        "base": 1.55,
        "stops": [
          [4, 0.25],
          [20, 30]
        ]
      }
    }
  },
  // bridge_major_case
  {
    id: "bridge_major_case",
    type: "line",
    source: "mapbox_streets",
    "source-layer": "road",
    filter: ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "bridge"],
        ["in", "class", "motorway", "primary", "secondary", "trunk"]
      ]
    ],
    paint: {
      "line-color": "#dedede",
      "line-width": {
        base: 1.4,
        stops: [
          [12, .5],
          [20, 10]
        ]
      },
      "line-gap-width": {
        base: 1.4,
        stops: [
          [6, .5],
          [20, 30]
        ]
      }
    }
  },
  // bridge_major
  {
    "id": "bridge_major",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "road",
    "filter": ["all", ["==", "$type", "LineString"],
      ["all", ["==", "structure", "bridge"],
        ["in", "class", "motorway", "primary", "secondary", "trunk"]
      ]
    ],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#fff",
      "line-width": {
        "base": 1.4,
        "stops": [
          [6, 0.5],
          [20, 30]
        ]
      }
    }
  },
  // admin_country
  {
    "id": "admin_country",
    "type": "line",
    "source": "mapbox_streets",
    "source-layer": "admin",
    "filter": ["all", ["==", "$type", "LineString"],
      ["all", ["<=", "admin_level", 2],
        ["==", "maritime", 0]
      ]
    ],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#8b8a8a",
      "line-width": {
        "base": 1.3,
        "stops": [
          [3, 0.5],
          [22, 15]
        ]
      }
    }
  },

  // parcels_fill
  {
    "id": "parcel_view_vic_fill",
    "type": "fill",
    "source": "parcel_view_vic",
    "source-layer": "parcel_view_vic",
    "minzoom": 13,
    "filter": ["==", "$type", "Polygon"],
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-opacity": 0
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "lot_nsw",
    "type": "line",
    "source": "lot_nsw",
    "source-layer": "lot_nsw",
    "minzoom": 13,
    "layout": {
      "visibility": "visible",
      "line-cap": "butt",
      "line-join": "miter",
      "line-miter-limit": 2
    },
    "paint": {
      "line-width": 0.3,
      "line-color": {
        "base": 1,
        "stops": [
          [13, "#aaa"],
          [22, "#585959"]
        ]
      },
      "line-opacity": {
        base: 1,
        stops: [
          [13, 0],
          [15, .8],
          [19, 1]
        ]
      }
    },
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  // parcels
  {
    "id": "parcel_view_vic",
    "type": "line",
    "source": "parcel_view_vic",
    "source-layer": "parcel_view_vic",
    "minzoom": 13,
    "filter": ["==", "$type", "Polygon"],
    "layout": {
      "visibility": "visible",
      "line-cap": "butt",
      "line-join": "miter",
      "line-miter-limit": 2
    },
    "paint": {
      "line-width": 0.3,
      "line-color": {
        "base": 1,
        "stops": [
          [13, "#aaa"],
          [22, "#585959"]
        ]
      },
      "line-opacity": {
        base: 1,
        stops: [
          [13, 0],
          [15, .8],
          [19, 1]
        ]
      }
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "lot_nsw_fill",
    "type": "fill",
    "source": "lot_nsw",
    "source-layer": "lot_nsw",
    "minzoom": 13,
    "filter": ["==", "$type", "Polygon"],
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-opacity": 0
    },
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  // Min Lot Size
  {
    id: "lot_size",
    type: "fill",
    source: "lot_size",
    "source-layer": "lot_size",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": ["coalesce", ["get", "fill_color"], "rgba(0,0,0,0.0)"],
      "fill-opacity": 0.6,
      "fill-antialias": !0,
      "fill-outline-color": "#000000"
    },
    meta: {
      for: ['map_view']
    }
  },
  // Floor space ratio
  {
    id: "fsr",
    type: "fill",
    source: "fsr",
    "source-layer": "fsr",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": ["coalesce", ["get", "fill_color"], "rgba(0,0,0,0.0)"],
      "fill-opacity": 0.6,
      "fill-antialias": !0,
      "fill-outline-color": "#000000"
    },
    meta: {
      for: ['map_view']
    }
  },
  // heritage
  {
    id: "heritage",
    type: "fill",
    source: "heritage",
    "source-layer": "heritage",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": ["coalesce", ["get", "fill_color"], "rgba(0,0,0,0.0)"],
      "fill-opacity": 0.4
    },
    meta: {
      for: ['map_view']
    }
  },
  // ugb
  {
    id: "ugb",
    type: "line",
    source: "ugb",
    "source-layer": "ugb",
    layout: {
      visibility: "none"
    },
    paint: {
      "line-color": "#005ce6"
    },
    meta: {
      for: ['map_view']
    }
  },
  // Land use
  {
    id: "land_use",
    type: "fill",
    source: "land_use",
    "source-layer": "land_use",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": ["get", "fill_color"],
      "fill-opacity": .6
    },
    meta: {
      for: ['map_view']
    }
  },
  // Precinct Structure Plan (psp)
  {
    id: "psp_pre_commencement",
    type: "fill",
    source: "psp",
    "source-layer": "psp",
    filter: ['==', 'STATUS_DES', 'Pre Commencement'],
    layout: {
      visibility: "none"
    },
    paint: {
      'fill-color': '#F47721',
      "fill-opacity": .6
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    id: "psp_under_preparation",
    type: "fill",
    source: "psp",
    "source-layer": "psp",
    filter: ['==', 'STATUS_DES', 'Under Preparation'],
    layout: {
      visibility: "none"
    },
    paint: {
      'fill-color': '#00B2A9',
      "fill-opacity": .6
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    id: "psp_unprogrammed",
    type: "fill",
    source: "psp",
    "source-layer": "psp",
    filter: ['==', 'STATUS_DES', 'Unprogrammed'],
    layout: {
      visibility: "none"
    },
    paint: {
      'fill-color': '#FDEC96',
      "fill-opacity": .6
    },
    meta: {
      for: ['map_view']
    }
  },
  // Height of building
  {
    id: "hob",
    type: "fill",
    source: "hob",
    "source-layer": "hob",
    layout: {
      visibility: "none"
    },
    paint: {
      "fill-color": ["coalesce", ["get", "fill_color"], "rgba(0,0,0,0.0)"],
      "fill-opacity": .6,
      "fill-antialias": !0,
      "fill-outline-color": "#000000"
    },
    meta: {
      for: ['map_view']
    }
  },
  // properties_fill
  // properties

  // bushfire prone
  {
    "id": "bushfire",
    "type": "fill",
    "source": "bushfire",
    "source-layer": "bushfire",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "fill-color": "#6E3070",
      "fill-opacity": 0.6
    },
    meta: {
      for: ['map_view']
    }
  },

  // poi_label
  {
    "id": "poi_label",
    "type": "symbol",
    "source": "mapbox_streets",
    "source-layer": "poi_label",
    "minzoom": 5,
    "filter": ["all", ["==", "$type", "Point"],
      ["all", ["==", "localrank", 1],
        ["==", "scalerank", 1]
      ]
    ],
    "layout": {
      "text-size": 11,
      "text-font": ["Lato Regular", "Arial Unicode MS Bold"],
      "visibility": "visible",
      "text-offset": [0, 0.5],
      "icon-size": 1,
      "text-anchor": "top",
      "text-field": "{name_en}",
      "text-max-width": 8
    },
    "paint": {
      "text-halo-blur": 1,
      "text-halo-width": 1,
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-color": "#585959"
    }
  },
  // road_major_label
  {
    "id": "road_major_label",
    "type": "symbol",
    "source": "mapbox_streets",
    "source-layer": "road_label",
    "filter": ["all", ["==", "$type", "LineString"],
      ["in", "class", "motorway", "primary", "secondary", "trunk"]
    ],
    "layout": {
      "symbol-placement": "line",
      "text-field": "{name_en}",
      "text-font": ["Lato Regular", "Arial Unicode MS Bold"],
      "text-transform": "uppercase",
      "text-letter-spacing": 0.1,
      "text-size": {
        "base": 1.4,
        "stops": [
          [10, 8],
          [20, 14]
        ]
      },
      "visibility": "visible"
    },
    "paint": {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 2
    }
  },
  // road_minor_label
  {
    "id": "road_minor_label",
    "type": "symbol",
    "source": "mapbox_streets",
    "source-layer": "road_label",
    "minzoom": 7,
    "filter": ["in", "class", "street", "tertiary"],
    "layout": {
      "text-field": "{name_en}",
      "text-font": ["Lato Regular", "Arial Unicode MS Bold"],
      "text-size": {
        "base": 1.4,
        "stops": [
          [14, 6],
          [18, 10]
        ]
      },
      "text-letter-spacing": 0.1,
      "text-transform": "uppercase",
      "symbol-placement": "line",
      "symbol-avoid-edges": false,
      "text-anchor": "center"
    },
    "paint": {
      "text-color": "#585959",
      "text-opacity": {
        "base": 1,
        "stops": [
          [0, 0],
          [22, 1]
        ]
      }
    }
  },
  // lot size centroids
  {
    id: "lot_size_centroids",
    type: "symbol",
    source: "lot_size",
    "source-layer": "lot_size",
    minzoom: 12,
    layout: {
      "text-field": "{lot_size} {units}",
      "text-size": {
        stops: [
          [12, 10],
          [14, 12],
          [16, 14],
          [18, 16]
        ]
      },
      "symbol-placement": "point",
      visibility: "none"
    },
    paint: {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    id: "fsr_centroids",
    type: "symbol",
    source: "fsr",
    "source-layer": "fsr",
    minzoom: 12,
    layout: {
      "text-field": "{fsr}",
      "text-size": {
        stops: [
          [15, 12],
          [20, 16]
        ]
      },
      "symbol-placement": "point",
      visibility: "none"
    },
    paint: {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    },
    meta: {
      for: ['map_view']
    }
  },
  // country_label
  {
    "id": "country_label",
    "type": "symbol",
    "source": "mapbox_streets",
    "source-layer": "country_label",
    "maxzoom": 12,
    "filter": ["==", "$type", "Point"],
    "layout": {
      "text-field": "{name_en}",
      "text-font": ["Lato Regular", "Arial Unicode MS Regular"],
      "text-max-width": 10,
      "text-size": {
        "stops": [
          [3, 14],
          [8, 22]
        ]
      }
    },
    "paint": {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 1,
      "text-halo-blur": 1
    }
  },

  // {
  //   "id": "gs_parcel_view",
  //   "type": "line",
  //   "source": "gs_parcel_view",
  //   "source-layer": "gs_parcel_view",
  //   "minzoom": 13,
  //   "filter": ["==", "$type", "Polygon"],
  //   "layout": {
  //     "visibility": "none",
  //     "line-cap": "butt",
  //     "line-join": "miter",
  //     "line-miter-limit": 2
  //   },
  //   "paint": {
  //     "line-width": 0.3,
  //     "line-color": {
  //       "base": 1,
  //       "stops": [
  //         [13, "#aaa"],
  //         [22, "#585959"]
  //       ]
  //     }
  //   },
  //   for: ['map_view']
  // },

  // easement
  {
    "id": "easement",
    "type": "line",
    "source": "easement",
    "source-layer": "easement",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "line-color": "#ea1d25"
    },
    "minzoom": 13,
    meta: {
      for: ['map_view']
    }
  },
  
  // zone and overlay centroids
  {
    "id": "plan_overlay_vic_centroids",
    "type": "symbol",
    "source": "plan_overlay_vic",
    "source-layer": "plan_overlay_vic",
    "layout": {
      "visibility": "none",
      "text-field": ["get", "zone_code"],
      "text-size": {
        stops: [
          [12, 10],
          [14, 12],
          [16, 14],
          [18, 16]
        ]
      },
      "symbol-placement": "point"
    },
    paint: {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    },
    "minzoom": 8,
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "plan_zone_vic_centroids",
    "type": "symbol",
    "source": "plan_zone_vic",
    "source-layer": "plan_zone_vic",
    "layout": {
      "visibility": "none",
      "text-field": ["get", "zone_code"],
      "text-size": {
        stops: [
          [12, 10],
          [14, 12],
          [16, 14],
          [18, 16]
        ]
      },
      "symbol-placement": "point"
    },
    "minzoom": 8,
    paint: {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "plan_zone_nsw_centroids",
    "type": "symbol",
    "source": "plan_zone_nsw",
    "source-layer": "plan_zone_nsw",
    "layout": {
      "visibility": "none",
      "text-field": ["get", "sym_code"],
      "text-size": {
        stops: [
          [12, 10],
          [14, 12],
          [16, 14],
          [18, 16]
        ]
      },
      "symbol-placement": "point"
    },
    "minzoom": 8,
    paint: {
      "text-color": "#585959",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    },
    meta: {
      for: ['map_view']
    }
  },

  // localities
  {
    "id": "localities",
    "type": "line",
    "source": "localities",
    "source-layer": "localities",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "line-color": "#616161",
      "line-width": 2
    },
    minzoom: 6,
    meta: {
      for: ['map_view']
    }
  },
  // locality centroids
  {
    "id": "locality_centroids",
    "type": "symbol",
    "source": "localities",
    "source-layer": "localities",
    layout: {
      "visibility": "none",
      "text-field": "{loc_name}",
      "text-padding": 15
    },
    paint: {
      "text-color": "#444444",
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 2,
      "text-halo-blur": 1
    },
    minzoom: 6,
    meta: {
      for: ['map_view']
    }
  },

  // lga
  {
    "id": "lga",
    "type": "line",
    "source": "lga",
    "source-layer": "lga",
    "layout": {
      "visibility": "none"
    },
    filter: ["==", "$type", "Polygon"],
    paint: {
      "line-color": "#00007D",
      "line-width": 2
    },
    minzoom: 6,
    meta: {
      for: ['map_view']
    }
  },
  // lga centroids
  {
    "id": "lga_centroids",
    "type": "symbol",
    "source": "lga",
    "source-layer": "lga",
    layout: {
      "visibility": "none",
      "text-field": "{LGA_NAME}",
      "text-padding": 15
    },
    paint: {
      "text-color": "#00007D",
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 1,
      "text-halo-blur": 1
    },
    minzoom: 6,
    meta: {
      for: ['map_view']
    }
  },

  // place labels
  {
    "id": "place_label_other",
    "type": "symbol",
    "source": "mapbox_streets",
    "source-layer": "place_label",
    "minzoom": 8,
    filter: ["all", ["==", "$type", "Point"],
      ["in", "type", "hamlet", "neighbourhood", "suburb", "town", "village"]
    ],
    "layout": {
      "text-field": "{name_en}",
      "text-font": ["Lato Regular", "Arial Unicode MS Bold"],
      "text-max-width": 6,
      "text-size": {
        "stops": [
          [6, 12],
          [12, 16]
        ]
      },
      "visibility": "visible",
      "text-padding": 15
    },
    "paint": {
      "text-color": "#34C981",
      "text-halo-color": "hsla(0, 0%, 100%, 0.95)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    },
    for: ['map_view', 'add_new_address']
  },
  {
    id: "place_label_city",
    type: "symbol",
    source: "mapbox_streets",
    "source-layer": "place_label",
    maxzoom: 16,
    filter: ["all", ["==", "$type", "Point"],
      ["==", "type", "city"]
    ],
    layout: {
      "text-field": "{name_en}",
      "text-font": ["Lato Bold", "Arial Unicode MS Bold"],
      "text-max-width": 10,
      "text-size": {
        stops: [
          [3, 12],
          [8, 16]
        ]
      },
      visibility: "visible",
      "text-padding": 15
    },
    paint: {
      "text-color": "#34C981",
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 1,
      "text-halo-blur": 1,
      "text-opacity": 1
    }
  },

  // {
  //   "id": "gs_parcel_view_fill",
  //   "type": "fill",
  //   "source": "gs_parcel_view",
  //   "source-layer": "gs_parcel_view",
  //   "minzoom": 13,
  //   "filter": ["==", "$type", "Polygon"],
  //   "layout": {
  //     "visibility": "visible"
  //   },
  //   "paint": {
  //     "fill-color": "#aaa",
  //     "fill-opacity": 0,
  //     "fill-outline-color": "hsla(0, 0%, 67%, 0)"
  //   },
  //   for: ['map_view']
  // },

  // comp index
  {
    "id": "comp_index_cluster",
    "type": "circle",
    "source": "comp_index",
    "source-layer": "public.comps.geom",
    "filter": [">", "count", 1],
    "paint": {
      "circle-color": "#1565C0",
      "circle-radius": ["step", ["get", "count"], 15, 25, 20, 50, 30, 100, 40, 500, 50],
      "circle-opacity": 0.8,
      // "circle-stroke-width": 3,
      "circle-stroke-width": ["step", ["get", "count"], 3, 15, 5, 25, 7, 50, 9, 100, 11, 500, 12],
      "circle-stroke-color": "#1565C0",
      "circle-stroke-opacity": 0.4
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "comp_index",
    "type": "circle",
    "source": "comp_index",
    "source-layer": "public.comps.geom",
    "filter": ["==", "count", 1],
    "paint": {
      "circle-radius": ["match", ["get", "duplicates"], 1, 7,10],
      "circle-stroke-width": 2,
      "circle-stroke-color": "#fff"
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "comp_index_cluster_count",
    "type": "symbol",
    "source": "comp_index",
    "source-layer": "public.comps.geom",
    "filter": [">", "count", 1],
    "layout": {
      "text-field": "{count}",
      "text-size": 12,
      "text-allow-overlap": true
    },
    "paint": {
      "text-color": "#fff"
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "comp_index_duplicates_bg",
    "type": "circle",
    "source": "comp_index",
    "source-layer": "public.comps.geom",
    "filter": ["all", ["==", "count", 1], [">", "duplicates", 1]],
    "paint": {
      "circle-color": "#585959",
      "circle-translate": [10, -8],
      "circle-radius": 8
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "comp_index_duplicates",
    "type": "symbol",
    "source": "comp_index",
    "source-layer": "public.comps.geom",
    "filter": ["all", ["==", "count", 1], [">", "duplicates", 1]],
    "layout": {
      "text-field": "{duplicates}",
      "text-size": 12,
      "text-offset": [0.84, -0.7],
      "text-allow-overlap": true
    },
    "paint": {
      "text-color": "#fff"
    },
    meta: {
      for: ['map_view']
    }
  },

  // highlighted polygons
  {
    'id': 'property_lines',
    'type': 'line',
    'source': 'highlighted_properties',
    'layout': {
      'visibility': 'none',
      'line-join': 'round'
    },
    'minzoom': 13,
    'paint': {
      'line-width': 1,
      'line-dasharray': [3, 2]
    },
    'filter': [
      'all',
      ['==', 'dashed', '1'],
      ['==', '$type', 'Polygon']
    ],
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    'id': 'property_sub_lines',
    'type': 'line',
    'source': 'highlighted_properties',
    'layout': {
      'visibility': 'none',
      'line-join': 'round'
    },
    'minzoom': 13,
    'paint': {
      'line-width': 3
    },
    'filter': [
      'all',
      ['==', 'dashed', '0'],
      ['==', '$type', 'Polygon']
    ],
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    "id": "property_lines_fill",
    "type": "fill",
    "source": "highlighted_properties",
    "layout": {
      "visibility": "none"
    },
    "minzoom": 13,
    "paint": {
      "fill-opacity": 0.2
    },
    "filter": ["==", "$type", "Polygon"],
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    "id": "property_length_labels",
    "type": "symbol",
    "source": "highlighted_properties",
    "minzoom": 13,
    "layout": {
      "visibility": "none",
      "symbol-placement": "line-center",
      "text-field": "{length} m",
      "text-size": 12
    },
    "paint": {
      "text-halo-color": "#fff",
      "text-halo-width": 2
    },
    "filter": ["==", "$type", "LineString"],
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    "id": "property_area_label",
    "type": "symbol",
    "source": "highlighted_properties",
    "minzoom": 13,
    "layout": {
      "visibility": "none",
      "text-field": "{area} ㎡",
      "text-size": 14,
      "text-offset": [0, 0.6],
      "text-allow-overlap": true
    },
    "paint": {
      "text-halo-color": "#fff",
      "text-halo-width": 2
    },
    "filter": ["all", ["==", "$type", "Point"], ["has", "area"]],
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    "id": "property_multi_sale_label_bg",
    "type": "circle",
    "source": "highlighted_properties",
    "minzoom": 13,
    "filter": ["has", "multi_sale_index"],
    "paint": {
      "circle-color": "#fff",
      "circle-radius": 12,
      "circle-opacity": 0.9,
      "circle-translate": [0, -12]
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "property_multi_sale_label",
    "type": "symbol",
    "source": "highlighted_properties",
    "minzoom": 13,
    "layout": {
      "visibility": "none",
      "text-field": "{multi_sale_index}",
      "text-size": 16,
      "text-offset": [0, -0.8],
      "text-allow-overlap": true
    },
    "filter": ["all", ["==", "$type", "Point"], ["has", "multi_sale_index"]],
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },

  // shortlist and comp markers
  {
    "id": "shortlist-marker",
    "type": "symbol",
    "source": "shortlist",
    "layout": {
      "icon-size": 0.7,
      "icon-anchor": "bottom",
      "icon-allow-overlap": true,
      "text-field": "{label}",
      "text-size": 16,
      "text-justify": "center",
      "text-rotation-alignment": "viewport",
      "text-padding": 1,
      "text-offset": [0, -1.34],
      "text-anchor": "center",
      "visibility": "none"
    },
    "paint": {
      "text-color": "#FFF",
      "text-halo-width": 1.8
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "comp_index_marker",
    "type": "symbol",
    "source": "point",
    "layout": {
      "icon-size": 0.7,
      "icon-anchor": "bottom",
      "visibility": "none"
    },
    meta: {
      for: ['map_view']
    }
  },
  {
    "id": "point-marker",
    "type": "symbol",
    "source": "marker",
    "layout": {
      "icon-size": 0.7,
      "icon-image": 'marker',
      "icon-anchor": "bottom",
      "visibility": "none"
    },
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    "id": "point",
    "type": "circle",
    "source": "point",
    "layout": {
      "visibility": "none"
    },
    "paint": {
      "circle-radius": 10,
      "circle-color": '#F84C4C'
    },
    meta: {
      for: ['map_view', 'add_new_address']
    }
  },
  {
    "id": "polygon",
    "type": "line",
    "source": "polygon",
    "layout": {
      "visibility": "none",
      "line-cap": "butt",
      "line-join": "miter",
      "line-miter-limit": 2
    },
    "paint": {
      "line-width": 2,
      "line-color": "#333"
    },
    meta: {
      for: ['map_view']
    }
  }
]