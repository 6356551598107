export default [
  {
    path: '/auth/signup/company',
    name: 'auth.signup.company',
    component: () => import(/* webpackChunkName: "auth-signup" */ '@/pages/auth/SignupCompany.vue'),
    meta: {
      // layout: 'auth',
      requiresGuest: true
    }
  }, {
    path: '/auth/signin',
    name: 'auth.signin',
    component: () => import(/* webpackChunkName: "auth-signin" */ '@/pages/auth/SigninPage.vue'),
    meta: {
      // layout: 'auth',
      requiresGuest: true
    }
  }
]