<template>
  <v-btn-toggle
    :value="value"
    v-bind="$attrs"
    mandatory
    color="primary"
    :class="{ 'd-flex flex-grow-1': grow }"
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="(item, i) in items"
      :key="i"
      :value="item.value"
      v-bind:disabled="disabled"
      :class="{ 'flex-grow-1': grow }"
      height="40"
    >
      <div class="d-flex align-center">
        <v-icon
          size="20"
          left
          :color="value === item.value ? 'primary' : 'grey'"
          style="margin-top: 1px;"
        >{{ value === item.value ? '$mdi-radiobox-marked' : '$mdi-radiobox-blank' }}</v-icon>
        <span>{{ item.text }}</span>
      </div>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    items: {
      type: Array,
      default: () => []
    },
    grow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  }
}
</script>
