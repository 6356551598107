const { format } = require("date-fns")

const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/

module.exports = {
  isLeapYear: (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
  },
  dateMonthsShifted: (inputDate, months) => {
    let dt = inputDate

    if (typeof inputDate === 'string') {
      const [dd, mm, yyyy] = dt.split('/')

      dt = new Date(yyyy, mm - 1, dd)
    }

    dt.setMonth(dt.getMonth() + months)

    return dt
  },
  formattedDate: (v, _format) => {
    if (!v) {
      return ''
    } else if (regex.test(v)) {
      const [dd, mm, yyyy] = v.split('/')
  
      return format(new Date(yyyy, mm - 1, dd), _format)
    } else {
      return format(new Date(v), _format)
    }
  }
}