<template>
  <v-text-field
    :id="id"
    :value="value"
    outlined
    :hide-details="hideDetails"
    placeholder="### ### ###"
    v-mask="'+61 ### ### ###'"
    clearable
    v-bind="{ ...$attrs }"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'date-picker-text',
  props: {
    value: {
      type: String,
      default: null
    },
    id: {
      type: String
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>