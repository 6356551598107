var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.typeIds)?_c('div',[(_vm.eachLine)?_c('div',{staticClass:"d-flex flex-wrap gap-xs flex-column"},_vm._l((_vm.typesFromIds(_vm.typeIds)),function(item){return _c('div',{key:item.id},[(
            item.parent_id ||
            (item.parent_id === null && _vm.arrayUtil.howManyInArray(_vm.typeIds, _vm.childrenIds(item)) === 0)
          )?_c('v-chip',{attrs:{"small":"","label":""}},[(item.parent_id)?[_c('span',[_vm._v(_vm._s(_vm.parentTypeName(item.id))+" > "+_vm._s(item.name))])]:_c('span',[_vm._v(_vm._s(item.name))])],2):_vm._e()],1)}),0):_c('div',{staticClass:"d-flex flex-wrap gap-xs"},_vm._l((_vm.typesFromIds(_vm.typeIds)),function(item){return _c('div',{key:item.id},[(
            item.parent_id ||
            (item.parent_id === null && _vm.arrayUtil.howManyInArray(_vm.typeIds, _vm.childrenIds(item)) === 0)
          )?_c('v-chip',{attrs:{"small":"","label":""}},[(item.parent_id)?[_c('span',[_vm._v(_vm._s(_vm.parentTypeName(item.id))+" > "+_vm._s(item.name))])]:_c('span',[_vm._v(_vm._s(item.name))])],2):_vm._e()],1)}),0)]):_c('v-icon',{attrs:{"small":""}},[_vm._v("$mdi-lock")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }