<template>
  <v-form>
    <div>
      <label class="">Images (Accepts jpeg, jpg, png, gif)</label>
      <ImageUpload
        v-model="local.images"
        id="images"
        class="mt-1"
        @input="update('images', $event)"
      />
    </div>

    <div class="mt-4">
      <label class="">Documents (Accepts csv, xlsx, pdf, doc, docx)</label>
      <div v-if="local.docs.length === 0 && docsFormEditInd === -1" class="mb-n2">
        <v-alert
          border="left"
          text
          type="warning"
          dense
          class="mt-2"
        >
          <div>There are no documents added yet</div>
        </v-alert>
      </div>
      <div v-else-if="docsFormEditInd === -1">
        <v-card v-for="(item, i) in local.docs" :key="i" outlined class="d-flex align-center gap-md px-2 py-1 mb-2">
          <v-list dense class="py-0 w-100">
            <v-list-item class="px-0">
              <v-list-item-avatar class="my-0">
                <v-icon>{{ fileIcon(item.type) }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.originalname }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center gap-sm ml-auto">
                <v-btn icon @click="handleDeleteDocument(i)">
                  <v-icon>$mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
      <div v-if="docsFormEditInd !== -1">
        <v-form ref="docsForm">
          <v-row dense>
            <v-col cols="12" sm="7">
              <label for="document" class="caption">Select a File</label>
              <v-file-input
                id="document"
                v-model="documentForm.file"
                outlined
                dense
                show-size
                accept="application/pdf, application/msword, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                prepend-icon="$mdi-file"
                :loading="uploadingDocument"
                :rules="[$rules.required]"
                @change="handleDocumentInputChange($event)"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="5">
              <label for="label" class="caption">Label</label>
              <v-text-field
                id="label"
                v-model="documentForm.label"
                outlined
                dense
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex justify-end mt-2">
          <v-btn text @click="handleCancelDocumentForm">Cancel</v-btn>
          <v-btn color="primary" depressed class="ml-2" @click="handleSaveDocumentForm">Save</v-btn>
        </div>
      </div>

      <div
        v-if="docsFormEditInd === -1"
        class="text-center mt-1"
      >
        <v-btn
          depressed
          @click="handleAddDocument"
        ><v-icon left>$mdi-plus</v-icon>
          Add Document
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'

import ImageUpload from '@/components/inputs/ImageUpload'

const defaultDocumentForm = {
  file: null,
  path: null,
  originalname: null,
  type: null,
  label: null
}

export default {
  components: {
    ImageUpload
  },
  props: ['value', 'isEdit', 'uploadedImages', 'uploadedFiles'],
  data() {
    return {
      docsFormEditInd: -1,
      documentForm: structuredClone(defaultDocumentForm),
      uploadingDocument: false
    }
  },
  computed: {
    ...mapGetters('app', ['fileIcon']),
    local() {
      return this.value
    }
  },
  methods: {
    async handleDocumentInputChange(doc) {
      if (doc) {
        const formData = new FormData()

        formData.append("files", doc, doc.name)

        this.uploadingDocument = true

        try {
          const res = await api.post('/app/pre-upload', formData, {
            timeout: 30 * 60 * 1000
          })

          this.documentForm.path = res.data.path
          this.documentForm.originalname = res.data.originalname
          this.documentForm.type = doc.type
        } catch (e) {
          console.log(e.response.data)
        } finally {
          this.uploadingDocument = false
        }
      }
    },
    handleSaveDocumentForm() {
      if (this.$refs.docsForm.validate()) {
        this.update('docs', [...this.local.docs, this.documentForm])

        this.docsFormEditInd = -1
      }
    },
    handleCancelDocumentForm() {
      this.docsFormEditInd = -1
    },
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    handleAddDocument() {
      this.documentForm = structuredClone(defaultDocumentForm)
      this.docsFormEditInd = this.local.docs.length
      // const max = this.local.docs.length ? Math.max(...this.local.docs.map((item) => item.id)) : 0
      // this.$emit('input', {
      //   ...this.local,
      //   documents: [
      //     ...this.local.docs,
      //     {
      //       id: max + 1,
      //       file: null,
      //       type: null,
      //       label: null
      //     }
      //   ]
      // })

      // this.$nextTick(() => {
      //   this.documents = this.local.docs
      // })
    },
    handleDeleteDocument(i) {
      const docs = this.local.docs
      
      docs.splice(i, 1)

      this.update('docs', docs)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: dashed 1px rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.image-preview {
  border: solid 1px rgba(0, 0, 0, 0.54);
}
.image-close {
  position: absolute;
  top: -16px;
  right: -16px;
}
</style>