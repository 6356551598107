<template>
  <div>
    <div v-if="label" class="caption">{{ label }}</div>
    <div v-if="value" class="d-flex flex-wrap gap-xs">
      <v-chip
        v-for="v in value"
        :key="v"
        small
        label
        v-bind="$attrs"
      >
        {{ dropdownItemName(v, ky) }}
      </v-chip>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    unlocked: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => []
    },
    ky: String,
    label: {
      required: false
    }
  },
  computed: {
    ...mapGetters('dropdown', ['dropdownItemName']),
  }
}
</script>