<template>
  <div>
    <div id="map" style="position: relative; width: 100%; height: 240px;">
      <v-overlay
        absolute
        :value="!gnafPropertyPids"
      >
        <v-icon>$mdi-lock</v-icon>
      </v-overlay>
    </div>

    <v-card-text v-if="formData" :style="`height: calc(100vh - ${isEdit ? '394px' : '334px'}); overflow: auto;`">
      <v-row v-if="isMultiSale && address.length || !isMultiSale && address" dense class="mt-1">
        <v-col cols="12">
          <div>Zone & Overlay</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6">
          <ZoneOverlayField
            type="zone"
            label="Zone"
            :codes="itemUniqueZoneCodes"
            :state_abbreviation="stateAbbreviation"
          />
        </v-col>
        <v-col v-if="stateAbbreviation === 'VIC'" cols="6">
          <ZoneOverlayField
            type="overlay"
            label="Overlay"
            :codes="itemUniqueOverlayCodes"
            :state_abbreviation="stateAbbreviation"
          />
        </v-col>
      </v-row>

      <v-row dense v-if="isSalesComp">
        <v-col cols="12">
          <div>Sales Analysis</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Land Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Result from dividing the sale price (Excludes GST) by the total square metres of the land</span>
            </v-tooltip>
          </div>
          <div>{{ landRate ? `$${$options.filters.number(landRate)} PSQM` : '-' }}</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Building Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Result from dividing the sale price (Excludes GST) by the total square metres of lettable area of the building</span>
            </v-tooltip>
          </div>
          <div>{{ buildingRate ? `$${$options.filters.number(buildingRate)} PSQM` : '-' }}</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Developable Area Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Result from dividing the sale price (Excludes GST) by the total square metres of developable area</span>
            </v-tooltip>
          </div>
          <div>{{ developableAreaRate ? `$${$options.filters.number(developableAreaRate)} PSQM` : '-' }}</div>
        </v-col>
      </v-row>

      <v-row dense v-else>
        <v-col cols="12">
          <div>Lease Analysis</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-start caption">
            <span>Net Effective Rent</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Gross Face Rent</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Gross Effective Rent</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-start caption">
            <span>Base Rent (per month)</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <div>
              <div>Base Rent ($ PSQM)</div>
              <div>Building Area</div>
            </div>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <div>
              <div>Base Rent ($ PSQM)</div>
              <div>Land Area</div>
            </div>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <div>Building Analysis</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Site Coverage Ratio</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Footprint area / total land area</span>
            </v-tooltip>
          </div>
          <NormalField
            :value="siteCoverateRatio"
          ></NormalField>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Parking Ratio</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Num of Car Park Spaces / (Total Building Area / 100) e.g. Car Space = 5, Total Building GLA = 2,500sqm Parking Ratio = 5 / (2,500/100) = 0.20 It should be displayed as: 0.20 : 100 sq.m.</span>
            </v-tooltip>
          </div>
          <div v-if="parkingRatio">{{ parkingRatio }} : 100 SQM</div>
          <div v-else>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Office Buildout Ratio</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Office Area / Total Building Area.</span>
            </v-tooltip>
          </div>
          <div v-if="officeBuildingRatio">{{ officeBuildingRatio }}</div>
          <div v-else>-</div>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <div>Rental Analysis</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Gross Income</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Formula (equals to total income): Rent + Recoverable Outgoings</span>
            </v-tooltip>
          </div>
          <NormalField
            type="price"
            :value="grossIncome"
          ></NormalField>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Net Income</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Formula: Gross Income - Total Outgoings</span>
            </v-tooltip>
          </div>
          <NormalField
            type="price"
            :value="netIncome"
          ></NormalField>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Capitalisation Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Formula: Net Rent / Sale Price</span>
            </v-tooltip>
          </div>
          <NormalField
            type="percentage"
            :value="capitalisationRate"
          ></NormalField>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Market Gross Income</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Formula: Income Market Rent + Market Recoverage Outgoings</span>
            </v-tooltip>
          </div>
          <NormalField
            type="price"
            :value="marketGrossIncome"
          ></NormalField>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Market Net Income</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Formula: Gross Market Income - Income Market Expense</span>
            </v-tooltip>
          </div>
          <NormalField
            type="price"
            :value="marketNetIncome"
          ></NormalField>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-start caption">
            <span>Market Capitalisation Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Formual: Market Net Rent / Sale Price</span>
            </v-tooltip>
          </div>
          <NormalField
            type="percentage"
            :value="marketCapitalisationRate"
          ></NormalField>
        </v-col>
      </v-row>

      <v-row dense v-if="isLeaseComp">
        <v-col cols="12">
          <div>Additional Lease Analysis</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-start caption">
            <span>Net Effective Rent</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Gross Face Rent</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <span>Gross Effective Rent</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-start caption">
            <span>Base Rent (per month)</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <div>
              <div>Base Rent ($ PSQM)</div>
              <div>Building Area</div>
            </div>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-start caption">
            <div>
              <div>Base Rent ($ PSQM)</div>
              <div>Land Area</div>
            </div>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>-</span>
            </v-tooltip>
          </div>
          <div>-</div>
        </v-col>
      </v-row>
      <slot name="actions" />
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'
import { convertToSqm } from '@/helper/utils'
import { uniqueValues } from '@/helper/array'

import ZoneOverlayField from '@/components/attributes/ZoneOverlay'

export default {
  props: [
    'isMultiSale',
    'salesType',
    'address',
    'formData',
    'isEdit',
    'loading'
  ],
  components: {
    ZoneOverlayField
  },
  data() {
    return {
      $map: null
    }
  },
  computed: {
    ...mapState('property', [
      'gnafPropertiesSource'
    ]),
    ...mapGetters('app', [
      'mapStyle'
    ]),
    ...mapGetters(['isSalesComp', 'isLeaseComp']),
    stateAbbreviation() {
      if (this.isMultiSale && this.address.length) {
        return this.address[0].state_abbreviation
      } else if (!this.isMultiSale && this.address) {
        return this.address.state_abbreviation
      } else {
        return null
      }
    },
    gnafPropertyPids() {
      if (this.isMultiSale && this.address.length) {
        return this.address.map((p) => p.gnaf_property_pid)
      } else if (!this.isMultiSale && this.address) {
        return [this.address.gnaf_property_pid]
      }

      return null
    },
    gstFactor() {
      return this.formData.salesForm.gst_included ? 10 / 11 : 1
    },
    landRate() {
      if (this.formData.buildingForm.land_area.value && this.formData.salesForm.sale_price) {
        return (this.formData.salesForm.sale_price / convertToSqm(this.formData.buildingForm.land_area) * this.gstFactor)
      }

      return null
    },
    buildingRate() {
      if (this.formData.buildingForm.building_area.value && this.formData.salesForm.sale_price) {
        return (this.formData.salesForm.sale_price / convertToSqm(this.formData.buildingForm.building_area) * this.gstFactor)
      }

      return null
    },
    developableAreaRate() {
      if (this.formData.buildingForm.developable_area.value && this.formData.salesForm.sale_price) {
        return (this.formData.salesForm.sale_price / convertToSqm(this.formData.buildingForm.developable_area) * this.gstFactor)
      }

      return null
    },
    siteCoverateRatio() {
      if (this.formData.buildingForm.footprint_area.value && this.formData.buildingForm.land_area.value) {
        return (convertToSqm(this.formData.buildingForm.footprint_area) / convertToSqm(this.formData.buildingForm.land_area)).toFixed(4)
      } else {
        return null
      }
    },
    parkingRatio() {
      if (this.formData.buildingForm.building_area.value && this.formData.buildingForm.number_of_car_spaces) {
        return (this.formData.buildingForm.number_of_car_spaces / convertToSqm(this.formData.buildingForm.building_area) * 100).toFixed(2)
      }
      return null
    },
    officeBuildingRatio() {
      const officeAreaInd = [1, 2, 3, 4, 5, 6, 7].find((v) => this.formData.buildingForm[`area_${v}_type_id`] === 1)

      if (
        officeAreaInd &&
        this.formData.buildingForm[`area_${officeAreaInd}`]['value'] &&
        this.formData.buildingForm.building_area.value
      ) {
        return (convertToSqm(this.formData.buildingForm[`area_${officeAreaInd}`]) / convertToSqm(this.formData.buildingForm.building_area)).toFixed(2)
      }
      return null
    },
    grossIncome() {
      return this.formData.salesForm.passing_rent + this.formData.salesForm.recoverable_outgoings
    },
    netIncome() {
      return this.grossIncome - this.formData.salesForm.total_outgoings
    },
    capitalisationRate() {
      return this.netIncome / this.formData.salesForm.sale_price_gst_excl * 100
    },
    marketGrossIncome() {
      return this.formData.salesForm.market_rent + this.formData.salesForm.market_recoverable_outgoings
    },
    marketNetIncome() {
      return this.marketGrossIncome - this.formData.salesForm.market_total_outgoings
    },
    marketCapitalisationRate() {
      return this.marketNetIncome / (this.formData.salesForm.sale_price_gst_excl + this.formData.salesForm.market_adjustment) * 100
    },
    itemUniqueZoneCodes() {
      if (this.formData.salesForm.is_multi_sale) {
        let codes = []

        Object.keys(this.formData.individualBuildingForms).forEach((address_detail_pid) => {
          codes = [...codes, ...this.formData.individualBuildingForms[address_detail_pid].zone_codes]
        })

        return uniqueValues(codes)
      } else {
        return this.formData.buildingForm.zone_codes
      }
    },
    itemUniqueOverlayCodes() {
      if (this.formData.is_multi_sale) {
        let codes = []

        Object.keys(this.formData.individualBuildingForms).forEach((address_detail_pid) => {
          codes = [...codes, ...this.formData.individualBuildingForms[address_detail_pid].overlay_codes]
        })

        return uniqueValues(codes)
      } else {
        return this.formData.buildingForm.overlay_codes
      }
    }
  },
  watch: {
    address() {
      this.handleAddressesChange()
    }
  },
  created() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN
  },
  mounted() {
    this.initMap()
    this.handleAddressesChange()
    // if (this.address) {
    //   this.onAddressSelected()
    // }
  },
  methods: {
    ...mapActions('property', [
      'setGnafPropertiesSourceState',
      'getGnafProperties',
    ]),
    ...mapMutations('property', [
      'SET_STATE_ABBREVIATION'
    ]),
    async initMap() {
      await this.$nextTick()

      const mapContaienr = document.getElementById('map')

      this.$map = new mapboxgl.Map({
        container: mapContaienr,
        style: this.mapStyle('add_new_address'),
        zoom: 15,
        center: [144.946457, -37.840935],
        attributionControl: false
      });
    },
    /**
     * Set highlighted_properties source from state
     */
    setGnafPropertiesSource(visible = true) {
      this.$map.getSource('highlighted_properties').setData(visible ? this.gnafPropertiesSource : null)
    },

    /**
     * Toggle layers visibility
     */
    setGnafPropertiesLayersVisibility(visibility) {
      if (this.$map) {
        [
          'property_lines',
          'property_lines_fill',
          'property_length_labels',
          'property_area_label',
          ...(this.isMultiSale ? ['property_multi_sale_label'] : [])
        ].forEach((l) => {
          this.$map.setLayoutProperty(l, 'visibility', visibility)
        })
      }
    },

    /**
     * Bound gnaf properties
     */
    boundGnafProperties() {
      this.gnafPropertiesSource && this.$map.fitBounds(
        turf.bbox(this.gnafPropertiesSource),
        {
          duration: 300,
          padding: { left: 10, bottom: 10, top: 10, right: 10 }
        }
      )
    },
    handleAddressesChange() {
      if (this.gnafPropertyPids) {
        this.SET_STATE_ABBREVIATION(this.isMultiSale ? this.address[0].state_abbreviation : this.address.state_abbreviation)
        this.getGnafProperties({
          gnaf_property_pids: this.gnafPropertyPids
        }).then(() => {
          this.setGnafPropertiesSourceState({
            areasLabels: true,
            multiSaleLabels: this.isMultiSale ? true : false,
            gnafPropertyPids: this.gnafPropertyPids
          })
          this.setGnafPropertiesSource()
          this.setGnafPropertiesLayersVisibility('visible')
          this.boundGnafProperties()
        })
      } else {
        this.setGnafPropertiesLayersVisibility('none')
      }
    },
  }
}
</script>