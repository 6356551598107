<template>
  <v-btn-toggle
    :value="value"
    v-bind="$attrs"
    :class="{ 'd-flex flex-grow-1': grow }"
    color="primary"
    
    :multiple="multiple"
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="(item, i) in dropdowns[ky]"
      :key="i"
      :value="item.id"
      :class="{ 'flex-grow-1': grow }"
      height="40"
    >
      <div class="d-flex align-center">
        <v-icon
          v-if="multiple"  
          size="20"
          left
          :color="value.includes(item.id) ? 'primary' : ''"
        >{{ value.includes(item.id) ? '$mdi-checkbox-marked' : '$mdi-checkbox-blank-outline' }}</v-icon>
        <v-icon
          v-else
          size="20"
          left
          :color="value === item.id ? 'primary' : 'grey'"
          style="margin-top: 1px;"
        >{{ value === item.id ? '$mdi-radiobox-marked' : '$mdi-radiobox-blank' }}</v-icon>
        <span>{{ item.name }}</span>
      </div>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Number
    },
    ky: {
      type: String,
      required: true
    },
    grow: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      items: [{
        text: 'Yes',
        value: true
      }, {
        text: 'No',
        value: false
      }]
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns'])
  }
}
</script>
