<template>
  <div>
    <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <label for="sale_method_id" class="caption">
            {{ isSalesComp ? 'Sale Method (Optional)' : 'Lease Method (Optional)' }}
          </label>
          <DropdownSelect
            id="sale_method_id"
            v-model="local.sale_method_id"
            :ky="isSalesComp ? 'saleMethods' : 'leaseMethods'"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="current_status_id" class="caption">Current Status (Optional)</label>
          <DropdownSelect
            id="current_status_id"
            v-model="local.current_status_id"
            :ky="isSalesComp ? 'campaignStatuses' : 'leaseCampaignStatuses'"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row dense v-if="isSalesComp">
        <v-col cols="6">
          <v-checkbox
            v-model="local.is_foreclosed"
            label="This sale is a mortgagee sale"
            hide-details
            class="mt-0 pt-0"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <label for="market_start_dt" class="caption">Campaign Start Date (Optional)</label>
          <DatePickerText
            id="market_start_dt"
            v-model="local.market_start_dt"
            dense
            outlined
            :rules="[
              $rules.dateOrNullRule,
              () => validateCampaignDates('start')
            ]"
          />
        </v-col>
        <v-col cols="6">
          <label for="market_end_dt" class="caption">Campaign End Date (Optional)</label>
          <DatePickerText
            id="market_end_dt"
            v-model="local.market_end_dt"
            dense
            outlined
            :rules="[
              $rules.dateOrNullRule,
              () => validateCampaignDates('end')
            ]"
          />
        </v-col>
      </v-row>

      <ExpandPanel label="Add agent details" class="mt-4" content-class="pl-3">
        <v-row dense>
          <v-col cols="12">
            <div class="mt-2">
              <label for="agency1_id" class="caption">Agency 1</label>
              <v-autocomplete
                id="agency1_id"
                v-model="local.agency1_id"
                :items="contacts"
                item-text="company_name"
                item-value="id"
                outlined
                small-chips
                chips
                clearable
                hide-details
                prepend-inner-icon="$mdi-magnify"
                :loading="loadingContacts"
              >
              </v-autocomplete>
              <div class="text-right">
                <span
                  class="primary--text text-decoration-underline cursor-pointer caption"
                  @click="handleOpenAgencyModal('agency1_id')"
                >
                  Create a new agency
                </span>
              </div>
            </div>
  
            <div>
              <v-checkbox
                v-model="local.is_conjunction_sale"
                label="This is a conjunction Sale"
                hide-details
                class="mt-0 pt-0"
              />
            </div>
  
            <div v-if="local.is_conjunction_sale" class="mt-2">
              <label for="agency2_id" class="caption">Agency 2</label>
              <v-autocomplete
                id="agency2_id"
                v-model="local.agency2_id"
                :items="contacts"
                item-text="company_name"
                item-value="id"
                outlined
                small-chips
                chips
                clearable
                hide-details
                prepend-inner-icon="$mdi-magnify"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    small
                    label
                    @click="data.select"
                  >
                    {{ data.item.company_name }} {{ data.item.business_type_id ? '|' : '' }} {{ dropdownItemName(data.item.business_type_id, 'businessTypes') }}
                  </v-chip>
                </template>
              </v-autocomplete>
              <div class="text-right">
                <span
                  class="primary--text text-decoration-underline cursor-pointer caption"
                  @click="handleOpenAgencyModal('agency2_id')"
                >
                  Create a new agency
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>
      
      <ExpandPanel label="Add advertisement details" class="mt-4" content-class="pl-3">
        <v-row dense>
          <v-col cols="12">
            <div v-for="i in 3" :key="i">
              <div class="mt-3">
                <label :for="`ad${i}_url`" class="caption">Ad{{ i }} URL</label>
                <v-text-field
                  :id="`ad${i}_url`"
                  v-model="local.ads[`ad${i}_url`]"
                  outlined
                  hide-details
                  prefix="https://"
                />
              </div>
              <div class="mt-2">
                <label class="caption">Ad{{ i }} Screenshot</label>
                <ImageUpload
                  v-model="local.ads[`ad${i}_images`]"
                  :id="`ad${i}_images`"
                  @input="updateAds(`ad${i}_images`, $event)"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>
    </v-form>

    <v-dialog
      v-model="agencyModal"
      width="600"
      scrollable
      persistent
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">Create New Agency</v-card-title>
        <v-card-text class="mt-3 px-4">
          <ContactForm
            ref="agencyForm"
            v-model="agencyForm"
            @close="agencyModal = false"
            @completed="handleContactCreated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import ImageUpload from '@/components/inputs/ImageUpload'
import ContactForm from '@/components/forms/ContactForm'

import {
  defaultContactForm
} from '@/@propedia/default_forms'

export default {
  props: [
    'value'
  ],

  components: {
    ImageUpload,
    ContactForm
  },

  data() {
    return {
      valid: true,
      agencyModal: false,
      agencyForm: structuredClone(defaultContactForm),
      savingAgency: false,
      contacts: [],
      agencyKey: null,
      errorMessage: null
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapState('contact', ['loadingContacts']),
    ...mapGetters('dropdown', ['dropdownItemName']),
    ...mapGetters(['isSalesComp']),
    local() {
      return this.value
    }
  },

  mounted() {
    this.getAgencies()
  },

  methods: {
    ...mapActions('contact', ['createContact', 'getContacts']),
    validateCampaignDates(when) {
      if (this.local.market_start_dt && this.local.market_end_dt) {
        const [dd1, mm1, yyyy1] = this.local.market_start_dt.split('/')
        const [dd2, mm2, yyyy2] = this.local.market_end_dt.split('/')

        if (new Date(yyyy1, mm1 - 1, dd1) > new Date(yyyy2, mm2 - 1, dd2)) {
          return when === 'start' ? 'Start date shouldn\'t be more recent than end date' : 'End date should be more recent than start date'
        } else {
          return true
        }
      }

      return true
    },
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value })
    },
    updateAds(key, value) {
      this.$emit('input', {
        ...this.local,
        ads: {
          ...this.local.ads,
          [key]: value
        }
      })
    },
    async getAgencies() {
      this.contacts = await this.getContacts({
        main_types: ['Agency']
      })
    },
    handleOpenAgencyModal(key) {
      this.agencyKey = key
      this.agencyForm = structuredClone({
        ...defaultContactForm,
        main_type: 'Agency',
        is_company: true,
      }),
      this.agencyModal = true
    },
    async handleContactCreated(id) {
      await this.getAgencies()

      this.update([this.agencyKey], id)

      this.agencyModal = false
    }
  }
}
</script>