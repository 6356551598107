import format from 'date-fns/format'
import Vue from 'vue'

const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/

Vue.filter('date-format', function (v) {
  if (!v) {
    return ''
  } else if (regex.test(v)) {
    const [dd, mm, yyyy] = v.split('/')

    return format(new Date(yyyy, mm - 1, dd), 'dd MM yyyy')
  } else {
    return format(new Date(v), 'dd MM yyyy')
  }
})

Vue.filter('month-format', function (v) {
  if (!v) {
    return ''
  } else if (regex.test(v)) { // Is of DD/MM/YYYY format
    const [dd, mm, yyyy] = v.split('/')

    return format(new Date(yyyy, mm - 1, dd), 'MMMM yyyy')
  } else { // Other types
    return format(new Date(v), 'MMMM yyyy')
  }
})

Vue.filter('currency', function (value) {
  if (!value) return ''
  return `$${value}`
})

Vue.filter('percentage', function (value) {
  if (!value) return ''
  return `${value}%`
})

Vue.filter('area', function (value) {
  if (!value) return ''
  return `${value} ㎡`
})

Vue.filter('number', function (value) {
  if (value) {
    const twoPrecision = Math.round(+value * 100) / 100

    return twoPrecision.toLocaleString()
  } else {
    return null
  }
})

Vue.filter('two-precision', function (value) {
  // https://stackoverflow.com/questions/596467/how-do-i-convert-a-float-number-to-a-whole-number-in-javascript
  return value ? Math.round(+value * 100) / 100 : null
})

Vue.filter('capitalize', (val) => val ? val.charAt(0).toUpperCase() + val.slice(1) : null)
Vue.filter('fieldToLabel', (val) => val ? val.split('_').map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ') : null)