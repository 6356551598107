<template>
  <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
    <!-- <div class="d-flex align-end gap-md mb-1">
      <AreaProfileMenu
        v-if="!isMultiSale"
        :selected-profile="selectedProfile"
        :profiles="profiles"
        :sales-type="salesType"
        @input="handleProfileSelect"
      />
    </div> -->

    <v-row dense>
      <v-col cols="8">
        <label
          for="building_area"
          class="caption"
          :class="{ 'required': isBuildingAreaRequired }"
        >
          Building Area
          <span v-if="isBuildingAreaRequired">*</span>
        </label>
        <div class="d-flex align-start gap-sm">
          <CurrencyText
            id="building_area"
            v-model="local.building_area"
            hide-details
            :rules="isBuildingAreaRequired ? [$rules.required] : []"
            :units="dropdowns.areaUnits"
            class="flex-grow-1"
          />
          <DropdownSelect
            v-model="local.building_area_type_id"
            ky="buildingAreaTypes"
            hide-details
            :clearable="false"
            class="shrinked"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <label for="building_grade_id" class="caption">Building Grade (Optional)</label>
        <DropdownSelect
          id="building_grade_id"
          v-model="local.building_grade_id"
          hide-details
          ky="buildingGrades"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <label for="number_of_childcare_places" class="caption">Number of Childcare Places</label>
        <CurrencyText
          id="number_of_childcare_places"
          v-model="local.number_of_childcare_places"
          :has-unit="false"
          hide-details
          :with-precision="false"
        />
      </v-col>
      <v-col cols="6">
        <label for="number_of_rooms" class="caption">Number of Rooms/Keys</label>
        <CurrencyText
          id="number_of_rooms"
          v-model="local.number_of_rooms"
          :has-unit="false"
          hide-details
          :with-precision="false"
        />
      </v-col>
      <v-col cols="8">
        <label for="building_name" class="caption">Building Name (Optional)</label>
        <v-text-field
          v-model="local.building_name"
          id="building_name"
          outlined
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <label for="footprint_area" class="caption">Footprint Area (Optional)</label>
        <CurrencyText
          id="footprint_area"
          v-model="local.footprint_area"
          hide-details
          :units="dropdowns.areaUnits"
        />
      </v-col>
      <v-col cols="6">
        <label for="awning_height" class="caption">Awning Height (Optional)</label>
        <CurrencyText
          id="awning_height"
          v-model="local.awning_height"
          :has-unit="false"
          suffix="Meter(s)"
          hide-details
        />
      </v-col>
    </v-row>

    <ExpandPanel label="Area Breakdown" class="mt-3" content-class="pl-3">
      <v-row dense>
        <v-col cols="7">
          <label for="parking_type_ids" class="caption">Parking Types (Optional)</label>
          <DropdownSelect
            id="parking_type_ids"
            v-model="local.parking_type_ids"
            ky="parkingTypes"
            :multiple="true"
            hide-details
          />
        </v-col>
        <v-col cols="5">
          <label for="number_of_car_spaces" class="caption">Number of Car Spaces (Optional)</label>
          <CurrencyText
            id="number_of_car_spaces"
            v-model="local.number_of_car_spaces"
            :has-unit="false"
            suffix="space(s)"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <template v-for="i in 7">
          <v-col cols="7" :key="`type-${i}`">
            <label :for="`area_${i}_type_id`" class="caption">Space {{ i }}</label>
            <DropdownSelect
              :id="`area_${i}_type_id`"
              v-model="local[`area_${i}_type_id`]"
              ky="buildingSpaceTypes"
              hide-details
            />
          </v-col>
          <v-col cols="5" :key="`area-${i}`">
            <label :for="`area_${i}`" class="caption">Space {{ i }} Area</label>
            <CurrencyText
              :id="`area_${i}`"
              v-model="local[`area_${i}`]"
              hide-details
              :units="dropdowns.areaUnits"
            />
          </v-col>
        </template>
      </v-row>
    </ExpandPanel>

    <ExpandPanel label="Add More Building Info" class="mt-2" content-class="pl-3">
      <v-row dense>
        <v-col cols="6">
          <label for="year_built" class="caption">Year Built (Optional)</label>
          <v-text-field
            v-model="local.year_built"
            id="year_built"
            outlined
            placeholder="YYYY"
            hide-details
            :rules="[$rules.yearOrNullRule]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label for="year_rennovated" class="caption">Year Rennovated (Optional)</label>
          <v-text-field
            v-model="local.year_rennovated"
            id="year_rennovated"
            outlined
            placeholder="YYYY"
            hide-details
            :rules="[$rules.yearOrNullRule, (v) => !v || v >= local.year_built || 'Year renovated should be more recent than year built']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <label for="number_of_floors" class="caption">Number of Floors (Optional)</label>
          <CurrencyText
            id="number_of_floors"
            v-model="local.number_of_floors"
            :has-unit="false"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <v-col cols="6">
          <label for="number_of_basement_floors" class="caption">Number of Basement Floors (Optional)</label>
          <CurrencyText
            id="number_of_basement_floors"
            v-model="local.number_of_basement_floors"
            :has-unit="false"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_status_id" class="caption">Building Status (Optional)</label>
          <DropdownSelect
            id="building_status_id"
            v-model="local.building_status_id"
            hide-details
            ky="buildingStatuses"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_coverage_status_id" class="caption">Building Coverage Status (Optional)</label>
          <DropdownSelect
            id="building_coverage_status_id"
            v-model="local.building_coverage_status_id"
            hide-details
            ky="buildingCoverageStatuses"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_level" class="caption">Building Level (Optional)</label>
          <v-text-field
            v-model="local.building_level"
            id="building_level"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label for="building_level_coverage_status_id" class="caption">Building Level Coverage Status (Optional)</label>
          <DropdownSelect
            id="building_level_coverage_status_id"
            v-model="local.building_level_coverage_status_id"
            ky="buildingCoverageStatuses"
          />
        </v-col>
      </v-row>
    </ExpandPanel>

    <ExpandPanel label="Building Rating" class="mt-2" content-class="pl-3">
      <v-row dense>
        <v-col cols="12">
          <div v-if="local.ratings.length === 0 && ratingFormEditInd === -1" class="mb-n2">
            <v-alert
              border="left"
              text
              dense
              type="warning"
            >
              <div>There are no rating added yet</div>
              <div>You can add at up to 5 ratings</div>
            </v-alert>
          </div>
          <div v-else-if="ratingFormEditInd === -1">
            <v-card v-for="(item, i) in local.ratings" :key="i" outlined class="d-flex align-center gap-md px-2 py-1 mb-2">
              <div>
                <v-list-item-title>
                  {{ dropdownItemName(item.rating_scope_id, 'buildingRatingScopes') }} |
                  {{ dropdownItemName(item.rating_type_id, 'buildingRatingTypes') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-rating
                    :value="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    length="6"
                    half-increments
                    readonly
                    dense
                  />
                </v-list-item-subtitle>
              </div>
              <v-list-item-action class="flex-row align-center gap-sm ml-auto">
                <v-btn icon @click=handleEditRating(i)>
                  <v-icon small>$mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="handleDeleteRating(i)">
                  <v-icon small>$mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-card>
          </div>
          <div v-if="ratingFormEditInd !== -1">
            <v-form ref="ratingForm">
              <v-row dense>
                <v-col cols="4">
                  <DropdownSelect
                    v-model="ratingForm.rating_scope_id"
                    ky="buildingRatingScopes"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="4">
                  <DropdownSelect
                    v-model="ratingForm.rating_type_id"
                    ky="buildingRatingTypes"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="4">
                  <DropdownSelect
                    v-model="ratingForm.rating"
                    ky="buildingStarRatings"
                    item-value="value"
                    :clearable="false"
                    :rules="[$rules.required]"
                  />
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex align-center mt-2">
              <v-btn v-if="local.ratings.length !== ratingFormEditInd" text color="error" @click="handleDeleteRating">
                <v-icon left>$mdi-trash-can</v-icon>
                Delete
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="handleCancelRatingForm">Cancel</v-btn>
              <v-btn color="primary" depressed class="ml-2" @click="handleSaveRatingForm">Save</v-btn>
            </div>
          </div>
          <div
            v-if="local.ratings.length < 5 && ratingFormEditInd === -1"
            class="text-center mt-1"
          >
            <v-btn
              depressed
              @click="handleAddMoreRating"
            ><v-icon left>$mdi-plus</v-icon>
              Add Rating
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </ExpandPanel>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  defaultRatingForm
} from '@/@propedia/default_forms'

export default {
  props: [
    'value',
    'isMultiSale',
    'address',
    'addresses'
  ],
  components: {
  },

  data() {
    return {
      valid: true,
      profiles: [],
      selectedProfile: null,
      ratingFormEditInd: -1,
      ratingForm: structuredClone(defaultRatingForm)
    }
  },

  watch: {
    salesType() {
      this.selectedProfile = null
    },
    address() {
      // Get area profiles for address
      if (!this.isMultiSale && this.address) {
        this.getCompsFromAddressDetailPid({
          address_detail_pid: this.address.address_detail_pid,
          salesType: this.salesType
        }).then((res) => {
          this.profiles = res.data.items
        })
      }
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters(['salesType', 'isSalesComp']),
    ...mapGetters('dropdown', ['dropdownItemName']),
    local() {
      return this.value
    },
    isBuildingAreaRequired() {
      if (!this.isSalesComp) {
        return true
      } else {
        return this.local.existing_rights_of_transfer_id === 2
      }
    }
  },

  methods: {
    ...mapActions('property', ['getCompsFromAddressDetailPid']),
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    handleAddMoreRating() {
      this.ratingForm = structuredClone(defaultRatingForm)
      this.ratingFormEditInd = this.local.ratings.length
    },
    handleCancelRatingForm() {
      this.ratingFormEditInd = -1
    },
    handleEditRating(i) {
      this.ratingForm = structuredClone(this.local.ratings[i])
      this.ratingFormEditInd = i
    },
    handleDeleteRating(i = null) {
      const ratings = this.local.ratings
      const index = i ?? this.ratingFormEditInd

      ratings.splice(index, 1)
      this.update('ratings', ratings)

      this.ratingFormEditInd = -1
    },
    handleSaveRatingForm() {
      if (this.$refs.ratingForm.validate()) {
        if (this.ratingFormEditInd === this.local.ratings.length) { // Add new
          this.local.ratings = [...this.local.ratings, structuredClone(this.ratingForm)]
        } else { // Update
          this.local.ratings[this.ratingFormEditInd] = structuredClone(this.ratingForm)
        }

        this.ratingFormEditInd = -1
      }
    },
    // handleProfileSelect(item) {
    //   this.selectedProfile = item
    //   this.$emit('input', {
    //     ...this.local,
    //     'type_ids': item.type_ids,
    //     'is_strata_unit': item.is_strata_unit,
    //     'with_development_potential': item.with_development_potential,
    //     'with_permit_approved': item.with_permit_approved,

    //     'building_area': item.building_area,
    //     'building_area_type': item.combined.building_area_type,
    //     'land_area': item.land_area,
    //     'area_1_type_id': item.combined.area_1_type_id,
    //     'area_1': item.combined.area_1,
    //     'area_2_type_id': item.combined.area_2_type_id,
    //     'area_2': item.combined.area_2,
    //     'area_3_type_id': item.combined.area_3_type_id,
    //     'area_3': item.combined.area_3,
    //     'area_4_type_id': item.combined.area_4_type_id,
    //     'area_4': item.combined.area_4
    //   })
    // },
  }
}
</script>