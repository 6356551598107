<template>
  <div>
    <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="8">
          <div>
            <label for="market_rent" class="caption">Market Rent (Optional)</label>
            <div class="d-flex align-start gap-sm">
              <CurrencyText
                id="market_rent"
                v-model="local.market_rent"
                :has-unit="false"
                prefix="$"
                hide-details
              />
              <DropdownSelect
                v-model="local.market_rent_type_id"
                ky="leaseAmountTypes"
                class="shrinked"
                :clearable="false"
                hide-details
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <label for="market_recoverable_outgoings" class="caption">
            Market Recoverable Outgoings (Optional)
          </label>
          <CurrencyText
            id="market_recoverable_outgoings"
            v-model="local.market_recoverable_outgoings"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="market_irrecoverable_outgoings" class="caption">
            Market Nonrecoverable Outgoings (Optional)
          </label>
          <CurrencyText
            id="market_irrecoverable_outgoings"
            v-model="local.market_irrecoverable_outgoings"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="market_total_outgoings" class="caption">
            Market Total Outgoings (Optional)
          </label>
          <CurrencyText
            id="market_total_outgoings"
            v-model="local.market_total_outgoings"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="market_adjustment" class="caption">
            Market Adjustment
          </label>
          <CurrencyText
            id="market_adjustment"
            v-model="local.market_adjustment"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <label for="market_adjustment_comment" class="caption">
            Market Adjustment Comment
          </label>
          <v-textarea
            id="market_adjustment_comment"
            v-model="local.market_adjustment_comment"
            dense
            outlined
            hide-details
            rows="3"
          />
        </v-col>
        <v-col v-if="isSalesComp" cols="6">
          <label for="market_wale" class="caption">
            Wale
          </label>
          <CurrencyText
            id="market_wale"
            v-model="local.market_wale"
            :has-unit="false"
            suffix="Years"
            hide-details
          />
        </v-col>
        <v-col v-if="isSalesComp" cols="6">
          <label for="market_irr" class="caption">
            Market IRR
          </label>
          <CurrencyText
            id="market_irr"
            v-model="local.market_irr"
            :has-unit="false"
            suffix="%"
            hide-details
          />
        </v-col>
        <v-col v-if="isSalesComp" cols="6">
          <label for="reversionary_field" class="caption">
            Reversionary Yield (optional)
          </label>
          <CurrencyText
            id="reversionary_field"
            v-model="local.reversionary_field"
            :has-unit="false"
            hide-details
          />
        </v-col>
        <v-col v-if="isSalesComp" cols="6">
          <label for="initial_yield_fully_let" class="caption">
            Initial Yield Fully Let
          </label>
          <CurrencyText
            id="initial_yield_fully_let"
            v-model="local.initial_yield_fully_let"
            :has-unit="false"
            suffix="%"
            hide-details
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },

  props: [
    'value'
  ],

  data() {
    return {
      valid: true
    }
  },

  computed: {
    ...mapGetters(['isSalesComp']),
    local() {
      return this.value
    }
  },

  methods: {
  }
}
</script>