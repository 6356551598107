<template>
  <div>
    <div v-if="showAddressSearch" class="px-6 my-4">
      <SuburbAutoComplete
        v-model="local.address"
        clearable
        outlined
        dense
        hide-details
        placeholder="e.g, 146 YARRA STREET ABBOTSFORD VIC 3067"
        class="mt-2"
        @input="update('address', $event)"
      />
    </div>
    <v-expansion-panels v-model="panel" accordion multiple flat class="mb-12" tile>
      <!-- <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Drawn Area</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip label close>
            <v-icon left>$mdi-vector-polygon</v-icon>
            Drawn Polygon Area
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span style="line-height: 1.2rem;" class="flex-grow-0">Property Types</span>
          <v-chip v-if="typesPrefix" small label class="ml-1 flex-grow-0">{{ typesPrefix }}</v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <PropertyTypeMultiSelect
            v-model="local.typeIds"
            dense
            outlined
            hide-details
            :limited="true"
            :modal-browsing="false"
            @input="update('typeIds', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
  
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">{{ datePrefix }}</span>
          <span v-if="dateSuffix" class="ml-1 caption primary--text">({{ dateSuffix }})</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <DateRangeSearch
            v-model="local.dateRange"
            @input="update('dateRange', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">{{ pricePrefix }}</span>
          <span v-if="priceSuffix" class="ml-1 caption primary--text">({{ priceSuffix }})</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <PriceRangeSearch
            v-model="local.priceRange"
            @input="update('priceRange', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Building Size</span>
          <span v-if="buildingSizeSuffix" class="ml-2 caption primary--text">{{ buildingSizeSuffix }} SQM</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <label for="building-size-min" class="caption">Min</label>
              <CurrencyText
                id="building-size-min"
                v-model="local.buildingSizeRange.from"
                bouncing-time="500"
                @debounced="update('buildingSizeRange', {
                  ...local.buildingSizeRange,
                  from: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="12">
              <label for="building-size-max" class="caption">Max</label>
              <CurrencyText
                id="building-size-max"
                v-model="local.buildingSizeRange.to"
                bouncing-time="500"
                @debounced="update('buildingSizeRange', {
                  ...local.buildingSizeRange,
                  to: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Land Size</span>
          <span v-if="landSizeSuffix" class="ml-2 caption primary--text">{{ landSizeSuffix }} SQM</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <label for="land-size-min" class="caption">Min</label>
              <CurrencyText
                id="land-size-min"
                v-model="local.landSizeRange.from"
                bouncing-time="500"
                @debounced="update('landSizeRange', {
                  ...local.landSizeRange,
                  from: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="12">
              <label for="land-size-max" class="caption">Max</label>
              <CurrencyText
                id="land-size-max"
                v-model="local.landSizeRange.to"
                bouncing-time="500"
                @debounced="update('landSizeRange', {
                  ...local.landSizeRange,
                  to: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Zone and Overlay</span>
          <v-chip v-if="zonePrefix" small label class="ml-1 flex-grow-0">{{ zonePrefix }}</v-chip>
          <span v-if="zonePrefix" class="ml-1 caption primary--text">{{ zoneSuffix }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ZoneOverlaySearch
            :zones="local.zoneCodes"
            :overlays="local.overlayCodes"
            @input-zones="update('zoneCodes', $event)"
            @input-overlays="update('overlayCodes', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
// Mixin
import { mapState } from 'vuex'

import validateRouteMixin from '@/@propedia/mixins/validate_route'

// Components
import SuburbAutoComplete from '@/components/SuburbAutoComplete'
import PropertyTypeMultiSelect from '@/components/PropertyTypeMultiSelect'
import DateRangeSearch from '@/components/inputs/search/DateRangeSearch'
import PriceRangeSearch from '@/components/inputs/search/PriceRangeSearch'
import ZoneOverlaySearch from '@/components/inputs/search/ZoneOverlaySearch'
import CurrencyText from '@/components/inputs/CurrencyText'

export default {
  components: {
    SuburbAutoComplete,
    PropertyTypeMultiSelect,
    DateRangeSearch,
    PriceRangeSearch,
    ZoneOverlaySearch,
    CurrencyText
  },
  
  mixins: [validateRouteMixin],

  props: {
    value: {
      type: Object
    },
    showAddressSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      panel: [0]
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    local() {
      return this.value
    },
    typesPrefix() {
      return this.local.typeIds.length
    },
    datePrefix() {
      return this.isSalesComp ? 'Sale Date' : 'Commencement Date'
    },
    dateSuffix() {
      if (!this.local.dateRange) return null

      switch (this.local.dateRange.value) {
        case 'last_3':
          return 'Last 3 Month'
        case 'last_6':
          return 'Last 6 Month'
        case 'last_12':
          return 'Last 12 Month'
        case 'custom':
          return `${this.local.dateRange.from_dt || ''} - ${this.local.dateRange.to_dt || ''}`
        default:
          return null
      }
    },
    pricePrefix() {
      return this.isSalesComp ? 'Sale Price' : 'Commencing Rent'
    },
    priceSuffix() {
      if (!this.local.priceRange) return null

      switch (this.local.priceRange.value) {
        case 'under_5':
          return 'Under 5M'
        case '5_to_10':
          return '5M - 10M'
        case '10_to_20':
          return '10M - 20M'
        case 'custom':
          return `${this.local.priceRange.from_price || ''} - ${this.local.priceRange.to_price || ''}`
        default:
          return null
      }
    },
    buildingSizeSuffix() {
      if (this.local.buildingSizeRange.from.value && this.local.buildingSizeRange.to.value) {
        return `${this.local.buildingSizeRange.from.value} - ${this.local.buildingSizeRange.to.value}`
      } else if (!this.local.buildingSizeRange.from.value && this.local.buildingSizeRange.to.value) {
        return `< ${this.local.buildingSizeRange.to.value}`
      } else if (this.local.buildingSizeRange.from.value && !this.local.buildingSizeRange.to.value) {
        return `> ${this.local.buildingSizeRange.from.value}`
      }

      return null
    },
    landSizeSuffix() {
      if (this.local.landSizeRange.from.value && this.local.landSizeRange.to.value) {
        return `${this.local.landSizeRange.from.value} - ${this.local.landSizeRange.to.value}`
      } else if (!this.local.landSizeRange.from.value && this.local.landSizeRange.to.value) {
        return `< ${this.local.landSizeRange.to.value}`
      } else if (this.local.landSizeRange.from.value && !this.local.landSizeRange.to.value) {
        return `> ${this.local.landSizeRange.from.value}`
      }

      return null
    },
    zoneOverlayCodes() {
      return [...(this.local.zoneCodes ?? []), ...(this.local.overlayCodes ?? [])]
    },
    zonePrefix() {
      return this.zoneOverlayCodes.length ? this.zoneOverlayCodes[0] : null
    },
    zoneSuffix() {
      const len = this.zoneOverlayCodes.length

      if (len > 1) {
        return `and ${len - 1} more`
      } else {
        return null
      }
    }
  },

  methods: {
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value })
    }
  }
}
</script>

<style scoped>
  .v-expansion-panel {
    border-bottom: solid 1px #e0e0e0;
  }
</style>