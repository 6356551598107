<template>
  <v-select
    :id="id"
    :hide-details="hideDetails"
    v-bind="$attrs"
    :value="value"
    :items="dropdowns[ky]"
    :item-value="itemValue ? itemValue : 'id'"
    :item-text="itemText ? itemText : 'name'"
    :clearable="clearable"
    :multiple="multiple"
    outlined
    dense
    placeholder="Select"
    clear-icon="$mdi-close"
    @change="$emit('input', $event)"
  >
    <template v-if="$attrs.multiple" v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        label
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: [Number, String, Array],
    id: String,
    ky: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns'])
  },
  methods: {
    remove(item) {
      this.$emit('input', this.value.filter((v) => v !== item.id))
    }
  }
}
</script>