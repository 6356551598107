<template>
  <div>
    <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <label for="sale_price" class="caption required">
            {{ local.is_multi_sale ? 'Combined Total Sale Price' : 'Sale Price' }} <span>*</span>
          </label>
          <CurrencyText
            id="sale_price"
            v-model="local.sale_price"
            :has-unit="false"
            prefix="$"
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="sale_dt" class="caption required">Sale Date<span>*</span></label>
          <DatePickerText
            id="sale_dt"
            v-model="local.sale_dt"
            dense
            outlined
            hide-details
            :rules="[$rules.required, $rules.dateOrNullRule]"
          />
        </v-col>
      </v-row>

      <!-- GST Included -->
      <v-row dense class="mb-2">
        <v-col cols="6" class="d-flex align-center">
          <v-checkbox
            v-model="local.gst_included"
            label="Price includes GST"
            hide-details
            class="ml-n1"
          />
          <v-tooltip right max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
            </template>
            <span>Please tick this box if the Sale Price entered is GST inclusive.</span>
          </v-tooltip>
        </v-col>
      </v-row>
      
      <v-row v-if="typeIds.includes(100)" dense class="mb-3">
        <v-col cols="6">
          <label for="proportional_land_value" class="caption">
            Proportional Land Value
          </label>
          <CurrencyText
            id="proportional_land_value"
            v-model="local.proportional_land_value"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="proportional_building_value" class="caption">
            Proportional Building Value
          </label>
          <CurrencyText
            id="proportional_building_value"
            v-model="local.proportional_building_value"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
      </v-row>

      <!-- Payment Details -->
      <v-row dense>
        <v-col cols="12">
          <label for="settlement_term_type_id" class="caption">Payment details / Terms of sale</label>
          <DropdownSelect
            id="settlement_term_type_id"
            v-model="local.settlement_term_type_id"
            ky="settlementTermTypes"
            @input="handlePaymentDetailIdChange"
          />
        </v-col>
        <v-col v-if="local.settlement_term_type_id === 3" cols="12">
          <v-row dense style="margin-bottom: -26px;">
            <v-col cols="3"></v-col>
            <v-col cols="3"></v-col>
            <v-col cols="2" class="caption" style="padding-left: 12px;">$ Value (excl. GST)</v-col>
            <v-col cols="2" class="caption" style="padding-left: 4px;">$ Value (incl. GST)</v-col>
            <v-col cols="2" class="caption">Due Date</v-col>
          </v-row>
          <div v-for="i in 6" :key="i" class="mt-1">
            <label :for="`term_${i}`" class="caption">{{ i === 1 ? 'Deposit' : `Payment ${i}` }}</label>
            <v-row dense>
              <v-col cols="3">
                <CurrencyText
                  :id="`term_${i}`"
                  v-model="local.settlement_term_details[i - 1][0]"
                  suffix="%"
                  :has-unit="false"
                  hide-details
                />
              </v-col>
              <v-col cols="3">
                <CurrencyText
                  :id="`term_${i}`"
                  v-model="local.settlement_term_details[i - 1][1]"
                  suffix="Months"
                  :has-unit="false"
                  hide-details
                />
              </v-col>
              <v-col cols="2">
                <NormalField
                  type="price"
                  :value="termPrice(local, i, true)"
                  style="line-height: 40px; padding-left: 8px;"
                />
              </v-col>
              <v-col cols="2">
                <NormalField
                  type="price"
                  :value="termPrice(local, i, false)"
                  style="line-height: 40px;"
                />
              </v-col>
              <v-col cols="2">
                <NormalField
                  v-if="local.sale_dt && local.settlement_term_details[i - 1][1]"
                  :value="termDate(local, i)"
                  style="line-height: 40px;"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-if="local.settlement_term_type_id === 2 || local.settlement_term_type_id === 3" cols="12">
          <label for="settlement_term_commentary" class="caption">Terms of sale commentary</label>
          <v-textarea
            id="settlement_term_commentary"
            v-model="local.settlement_term_commentary"
            outlined
            :clearable="false"
            rows="3"
            @input="update('settlement_term_commentary', $event)"
          ></v-textarea>
        </v-col>
      </v-row>

      <ExpandPanel label="Current Sale Status, Ownership Transfer" class="mt-3" content-class="pl-3">
        <v-row dense>
          <v-col cols="12" class="d-flex flex-column">
            <label class="caption">Current Sale Status</label>
            <BtnSelection
              v-model="local.sale_status_id"
              ky="saleStatuses"
              mandatory
              grow
            />
          </v-col>
          <v-col cols="6">
            <label class="caption" for="ownership_transfer">Ownership Transfer</label>
            <CurrencyText
              id="ownership_transfer"
              v-model="local.ownership_transfer"
              suffix="%"
              :has-unit="false"
              hide-details
            />
          </v-col>
        </v-row>
      </ExpandPanel>

      <ExpandPanel label="Purchaser, Vender" class="mt-2" content-class="pl-3">
        <v-row dense>
          <v-col cols="12">
            <div>
              <label for="vendor_ids">Vendors</label>
              <v-autocomplete
                v-model="local.vendor_ids"
                :items="contacts"
                :item-text="v => contactItemText(v)"
                item-value="id"
                outlined
                hide-details
                multiple
                clearable
                small-chips
                chips
                prepend-inner-icon="$mdi-magnify"
              >
              </v-autocomplete>
              <div class="text-right">
                <span
                  class="primary--text text-decoration-underline cursor-pointer caption"
                  @click="handleOpenContactModal('Vendor')"
                >
                  Create a new vendor
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <label for="purchaser_ids">Purchasers</label>
              <v-autocomplete
                v-model="local.purchaser_ids"
                :items="contacts"
                :item-text="v => contactItemText(v)"
                item-value="id"
                no-filter
                outlined
                hide-details
                multiple
                clearable
                small-chips
                chips
                prepend-inner-icon="$mdi-magnify"
              >
              </v-autocomplete>
              <div class="text-right">
                <span
                  class="primary--text text-decoration-underline cursor-pointer caption"
                  @click="handleOpenContactModal('Purchaser')"
                >
                  Create a new purchaser
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>
  
      <v-row dense class="mt-2">
        <v-col cols="10">
          <div>
            <label class="caption required">Tenure Type<span>*</span></label>
            <v-tooltip right max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>The property has a tenant and lease in place. Lease details must be provided.</span>
            </v-tooltip>
          </div>
          <BtnSelection
            v-model="local.tenure_type_id"
            ky="tenureTypes"
            mandatory
          />
        </v-col>
        <v-col cols="12">
          <label for="analysis_comments" class="caption">Sale Analysis Comments</label>
          <v-textarea
            id="analysis_comments"
            v-model="local.analysis_comments"
            outlined
            hide-details
            :clearable="false"
            rows="3"
          />
        </v-col>
        <v-col cols="12">
          <label for="description" class="caption required">Sales Description / Commentary <span>*</span></label>
          <v-textarea
            id="description"
            v-model="local.description"
            outlined
            hide-details
            :clearable="false"
            rows="3"
            :rules="[$rules.required]"
          />
        </v-col>
      </v-row>

      <div v-if="local.tenure_type_id === 2">
        <v-row dense class="mb-2 mt-2">
          <v-col cols="8">
            <div>
              <label for="passing_rent" class="caption required">
                {{ local.is_multi_sale ? 'Combined Passing Rent' : 'Passing Rent' }}
                <span>*</span>
              </label>
              <div class="d-flex align-start gap-sm">
                <div class="flex-grow-1">
                  <CurrencyText
                    id="passing_rent"
                    v-model="local.passing_rent"
                    prefix="$"
                    hide-details
                    :has-unit="false"
                    :rules="[$rules.required]"
                  />
                  <v-checkbox
                    v-model="local.is_passing_rent_approx"
                    label="Approx"
                    hide-details
                    class="ml-n1 pt-0"
                  />
                </div>
                <DropdownSelect
                  v-model="local.passing_rent_type_id"
                  ky="leaseAmountTypes"
                  hide-details
                  class="shrinked"
                  :clearable="false"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>

    <v-dialog
      v-model="contactModal"
      width="600"
      scrollable
      persistent
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
          Create New {{ contactForm.main_type }}
        </v-card-title>
        <v-card-text class="mt-3 px-4">
          <ContactForm
            ref="contactForm"
            v-model="contactForm"
            @close="contactModal = false"
            @completed="handleContactCreated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import ContactForm from '@/components/forms/ContactForm'

import {
  defaultContactForm
} from '@/@propedia/default_forms'

export default {
  components: {
    ContactForm
  },

  props: [
    'value',
    'salesType',
    'isMultiSale',
    'typeIds',
    'address',
    'addresses',
    'leaseProfiles',
    'leaseSelected',
    'isLandAreaRequired'
  ],

  data() {
    return {
      valid: true,
      contactModal: false,
      contactForm: structuredClone({
        main_type: null,
        ...defaultContactForm
      }),
      contacts: [],
      errorMessage: null,
      vendorType: 'vendor'
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapState('contact', ['loadingContacts']),
    ...mapGetters('dropdown', ['dropdownItemName']),
    ...mapGetters('property', [
      'termPrice',
      'termDate'
    ]),
    local() {
      return this.value
    }
  },

  mounted() {
    this._getContacts()
  },

  methods: {
    ...mapActions('contact', ['createContact', 'getContacts']),
    handlePaymentDetailIdChange(v) {
      if (v !== 3) {
        this.update('settlement_term_details', [
          [null, null],
          [null, null],
          [null, null],
          [null, null],
          [null, null],
          [null, null]
        ])
      }
    },
    isLeaseProfileFilled(address_detail_pid) {
      if (!this.leaseProfiles) {
        return false
      }

      return !!(this.leaseProfiles[address_detail_pid].commencing_rent && this.leaseProfiles[address_detail_pid].lease_dt)
    },
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    async _getContacts() {
      this.contacts = await this.getContacts({
        main_types: ['Vendor', 'Purchaser']
      })
    },
    handleOpenContactModal(main_type = 'Vendor') {
      this.contactForm = structuredClone({
        ...defaultContactForm,
        main_type
      }),
      this.contactModal = true
    },
    async handleContactCreated(id) {
      await this._getContacts()

      if (this.contactForm.main_type === 'Vendor') {
        this.update('vendor_ids', [...this.local.vendor_ids, id])
      } else {
        this.update('purchaser_ids', [...this.local.purchaser_ids, id])
      }

      this.contactModal = false
    },
    contactItemText(item) {
      return `${item.is_company ? item.company_name : item.full_name} | ${this.dropdownItemName(item.ownership_type_id, 'ownershipTypes')}`
    }
  }
}
</script>