<template>
  <div class="">
    <label :for="id" class="image-input">
      Drag & Drop
    </label>

    <div class="d-flex flex-wrap gap-md mt-2">
      <div class="relative image-preview" v-for="(img, i) in local" :key="i">
        <v-hover v-slot="{ hover }">
          <v-img
            :src="img.path ?? img.url"
            width="100"
            height="100"
            style="border-radius: 4px;"
          >
            <v-row
              v-if="img.uploading"
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
            <v-row
              v-if="!img.uploading && hover"
              class="fill-height ma-0 gap-sm"
              align="center"
              justify="center"
            >
              <v-btn fab x-small elevation="1" class="" @click="handlePreview(img)">
                <v-icon>$mdi-fullscreen</v-icon>
              </v-btn>
              <v-btn fab x-small elevation="1" class="" @click="removeImage(img.id)">
                <v-icon>$mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-img>
        </v-hover>
      </div>
    </div>
    <v-file-input
      :id="id"
      class="d-none"
      multiple
      :accept="accept"
      @change="handleImageInputChange($event)"
    />

    <v-dialog v-model="preview" max-width="600">
      <v-card max-width="600">
        <v-img :src="previewImg"></v-img>
        <!-- <v-icon style="position: absolute; right: 8px; top: 8px;" @click="preview = false">$mdi-close</v-icon> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      images: this.value.map((v) => ({ ...v, uploading: false })),
      preview: false,
      previewImg: null
    }
  },
  computed: {
    local() {
      return this.value
    }
  },
  methods: {
    handleImageInputChange(v) {
      const max = this.local.length ? Math.max(...this.local.map((item) => item.id)) : 0
      const newImages = v.map((img, i) => ({
        id: max + i + 1,
        url: URL.createObjectURL(img),
        file: img,
        path: null,
        uploading: true,
        originalname: null
      }))

      this.images = [
        ...this.local,
        ...newImages
      ]

      newImages.forEach((v) => {
        const formData = new FormData()
        
        formData.append("files", v.file, v.file.name)

        api.post('/app/pre-upload', formData, {
          timeout: 30 * 60 * 1000
        }).then((res) => {
          v.uploading = false
          v.path = res.data.path
          v.originalname = res.data.originalname

          this.$emit('input', [
            ...this.local,
            v
          ])
        })
      })
    },
    removeImage(id) {
      this.$emit('input', this.local.filter((img) => img.id != id))
      this.$nextTick(() => {
        this.images = this.local
      })
    },
    handlePreview(img) {
      this.previewImg = img.path ?? img.url
      this.preview = true
    }
  }
}
</script>

<style lang="scss" scoped>
.image-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border: dashed 2px rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  cursor: pointer;
}
</style>