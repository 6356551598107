module.exports = {
  getVariableType: (item) => {
    if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
      return 'object'
    } else if (typeof item === 'object' && item !== null && Array.isArray(item)) {
      return 'array'
    } else if (typeof item === 'string') {
      return 'string'
    } else if (typeof item === 'number') {
      return 'number'
    } else {
      return 'other'
    }
  },
  removeEmpty: (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v)) // eslint-disable-line no-unused-vars
  },
  roundTo: (v, digits = 2) => {
    const multiplicator = Math.pow(10, digits);

    return v ? Math.round(multiplicator * v) / multiplicator : 'N/A'
  },
  getInitials: (v) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
    let initials = [...v.matchAll(rgx)] || []

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()

    return initials
  },
  alphabeticalIndex: (ind, isCapital = false) => {
    return String.fromCharCode((isCapital ? 'A' : 'a').charCodeAt() + ind)
  },
  mod: (n, m) => ((n % m) + m) % m,
  convertToSqm: (area) => {
    const unit = area.unit.toLowerCase()

    if (unit === 'sqm') {
      return area.value
    } else if (unit === 'ha') {
      return area.value * 10000
    } else if (unit === 'acre') {
      return area.value * 4046.86
    } else {
      return undefined
    }
  }
}