<template>
  <v-text-field
    v-model="cmpValue"
    v-bind:id="id"
    v-bind:label="label"
    v-bind:placeholder="placeholder"
    v-bind:readonly="readonly"
    v-bind:disabled="disabled"
    v-bind:hide-details="hideDetails"
    outlined
    v-bind:error="error"
    v-bind:error-messages="errorMessages"
    v-bind:rules="rules"
    v-bind:prefix="prefix"
    v-bind:suffix="suffix"
    v-bind:clearable="clearable"
    v-bind="properties"
    v-on:keypress="keyPress"
    v-on:blur="onBlur"
  >
    <template v-if="hasUnit" #append>
      <v-select
        v-model="local.unit"
        :items="units"
        hide-details
        solo
        flat
        class="unit"
        :clearable="false"
        :menu-props="{ nudgeBottom: 4, offsetY: true }"
        style="max-width: 88px;"
        @change="$emit('input', { ...local, unit: $event })"
      ></v-select>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: { prop: "value", event: "input" },
  props: {
    value: {
      type: [Object, String, Number],
      default: null
    },
    hasUnit: {
      type: Boolean,
      default: true
    },
    units: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    errorMessages: {
      type: [Array, String],
      default: () => []
    },
    rules: {
      type: [Array, String],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    valueWhenIsEmpty: {
      type: String,
      default: '' // '0' or ' or null
    },
    valueOptions: {
      type: Object,
      default: function() {
        return {
          min: 0,
          minEvent: 'SetValueMin'
        };
      }
    },
    options: {
      type: Object,
      default: function() {
        return {
          locale: 'en-US',
          length: 11,
          precision: 2
        }
      }
    },
    withPrecision: {
      type: Boolean,
      default: true
    },
    // Other v-text-field properties
    properties: {
      type: Object,
      default: function() {
        return {};
      }
    },
    id: {
      type: String
    },
    bouncingTime: {
      type: [Number, String]
    }
  },
  data() {
    return {
      timerId: null
    }
  },
  computed: {
    local() {
      return this.value
    },
    cmpValue: {
      get: function() {
        if (this.hasUnit) {
          return this.value.value !== null && this.value.value !== ''
            ? this.humanFormat(this.value.value.toString())
            : this.valueWhenIsEmpty;
        } else {
          return this.value !== null && this.value !== ''
            ? this.humanFormat(this.value.toString())
            : this.valueWhenIsEmpty;
        }
      },
      set: function(newValue) {
        if (this.hasUnit) {
          this.$emit('input', {
            ...this.local,
            value: this.machineFormat(newValue)
          })
        } else {
          this.$emit('input', this.machineFormat(newValue))
        }

        if (this.bouncingTime) {
          clearTimeout(this.timerId)
  
          this.timerId = setTimeout(() => {
            if (this.hasUnit) {
              this.$emit('debounced', {
                ...this.local,
                value: this.machineFormat(newValue)
              })
            } else {
              this.$emit('debounced', this.machineFormat(newValue))
            }
          }, +this.bouncingTime)
        }
      }
    }
  },
  methods: {
    humanFormat: function(number) {
      if (isNaN(number)) {
        number = ''
      } else {
        number = Number(number).toLocaleString(this.options.locale)
        // number = Number(number).toLocaleString(this.options.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
        // number = Number(number).toLocaleString(this.options.locale, {
        //   maximumFractionDigits: this.options.precision,
        //   minimumFractionDigits: this.options.precision
        // })
      }

      return number
    },
    machineFormat(number) {
      if (number) {
        number = this.cleanNumber(number);
        // Adjust amount of leading zeros
        // number = number.padStart(parseInt(this.options.precision) + 1, '0');
        // Include point in the correct house, according to the configured precision
        // number =
        //   number.substring(
        //     0,
        //     number.length - parseInt(this.options.precision)
        //   ) +
        //   '.' +
        //   number.substring(
        //     number.length - parseInt(this.options.precision),
        //     number.length
        //   );
        if (isNaN(number)) {
          number = this.valueWhenIsEmpty;
        }
      } else {
        number = this.valueWhenIsEmpty;
      }

      if (!this.withPrecision) {
        number = this.cleanNumber(number)
      }

      return number
    },
    keyPress($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      } else if (keyCode === 46 && !this.withPrecision) { // 46 is dot
        $event.preventDefault()
      }

      if (this.targetLength()) {
        $event.preventDefault()
      }
    },
    // Strips all non-numeric characters except dot and leading zeros
    cleanNumber(value) {
      let result = ''

      if (value) {
        let flag = false;
        let arrayValue = value.toString().split('')

        for (var i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i]) || arrayValue[i] === '.') {
            if (!flag) {
              // Remove leading zeros
              if (arrayValue[i] !== '0') {
                result = result + arrayValue[i]
                flag = true
              }
            } else {
              result = result + arrayValue[i]
            }
          }
        }
      }

      return result
    },
    isInteger(value) {
      return Number.isInteger(parseInt(value)) ? true : false
    },
    targetLength() {
      const v = this.hasUnit ? this.value.value : this.value
        
      return this.cleanNumber(v).length > this.options.length ? true : false
      // if (
      //   Number(this.cleanNumber(this.value).length) >=
      //   Number(this.options.length)
      // ) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    onBlur() {
      if (!this.value) return
      if (
        this.value.length === 0 ||
        parseFloat(this.value) <= this.valueOptions.min
      )
        this.$emit(
          this.valueOptions.minEvent || 'SetValueMin',
          this.valueOptions.min
        );
      if (
        this.valueOptions.max &&
        parseFloat(this.value) >= this.valueOptions.max
      )
        this.$emit(
          this.valueOptions.maxEvent || 'SetValueMax',
          this.valueOptions.max
        );
    }
  }
}
</script>