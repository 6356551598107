<template>
  <div class="">
    <div class="front-page-navbar">
      <v-app-bar
        fixed
        elevate-on-scroll
        color="white"
        class="rounded-b-lg"
        style="top: 0px; z-index: 104; transform: translate(0px); left: 0px; width: calc(100% + 0px);"
      >
        <div class="d-flex align-center h-100 py-0 w-100">
          <LogoComponent />
          <v-spacer></v-spacer>
          <div class="d-none d-sm-flex gap-column-lg align-center">
            <template v-for="(link, i) in links">
              <v-menu
                v-if="link.items"
                open-on-hover
                offset-y
                transition="slide-y-transition"
                :key="i"
                nudge-bottom="6"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    v-on="on"
                    class="link-text"
                  >
                    {{ link.text }}
                  </a>
                </template>
                <v-list nav dense>
                  <v-list-item
                    v-for="(item, index) in link.items"
                    :key="index"
                    :to="item.to"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn v-else text :to="link.to" :key="i">{{ link.text }}</v-btn> -->
              <router-link
                v-else
                :to="link.to"
                :key="i"
                class="link-text"
              >{{ link.text }}</router-link>
            </template>
  
            <v-btn
              v-if="isAuth"
              color="primary"
              href="/browse/map?sales_type=salesComp"
            >
              <v-icon left class="mt-1">$mdi-magnify</v-icon>
              Browse comps
            </v-btn>
            <template v-else>
              <v-divider vertical />
              <v-btn to="/auth/signin" text>Login</v-btn>
              <v-btn to="/auth/signup" color="primary">Sign Up</v-btn>
            </template>
          </div>
          <v-btn class="d-block d-sm-none" icon @click="drawer = true">
            <v-icon>$mdi-menu</v-icon>
          </v-btn>
        </div>
      </v-app-bar>
    </div>

    <v-navigation-drawer absolute temporary v-model="drawer">
      <v-list>
        <template v-for="(link, i) in links">
          <v-list-group
            v-if="link.items"
            :key="i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="link.text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(item, j) in link.items"
              :key="j"
              :to="item.to"
              class="pl-8"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="i"
            :to="link.to"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ link.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-list-item
        to="/auth/signin"
      >
        <v-list-item-content>
          <v-list-item-title>
            Login
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer class="footer">
      <v-container class="d-flex justify-space-between px-0 px-sm-2">
        <v-row align="center">
          <v-col cols="8">
            <div style="font-size: 32px; font-weight: bold; margin-bottom: 18px;">Propedia 2024</div>
            <div class="d-flex gap-md">
              <v-icon color="white">$mdi-email-outline</v-icon>
              <v-icon color="white">$mdi-linkedin</v-icon>
              <v-icon color="white">$mdi-facebook</v-icon>
            </div>
          </v-col>
          <v-col cols="4">
            <ul>
              <li>
                <router-link to="/privacy-policy" class="">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/terms" class="">Terms & Conditions</router-link>
              </li>
              <li>
                <router-link to="/contact" class="">Contact</router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'

import LogoComponent from '../components/LogoComponent.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    LogoComponent
  },
  computed: {
    ...mapGetters('auth', ['isAuth'])
  },
  data() {
    return {
      drawer: false,
      links: [
        {
          text: 'Product',
          items: [
            {
              text: 'Propedia Explorer',
              to: '/propedia-explorer'
            },
            {
              text: 'Full Stack Tailored Development Service',
              to: '/fullstack-development-service'
            }
          ]
        },
        {
          text: 'About Us',
          to: '/teams'
        },
        {
          text: 'Support',
          to: '/contact'
        }
      ]
    }
  },
  created() {
    AOS.init()
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.link-text {
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  position: relative;
}
.link-text:hover {
  color: #0573E6;
}

.footer {
  background: #00356c;
  color: white;
  padding: 42px 0;
  a {
    color: white;
  }

  li {
    list-style: none;
    padding: 3px 0;
    transition: all ease 0.3s;
  }

  li:hover {
    padding-left: 4px;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .main {
    margin-top: 56px;
  }
  .footer {
    font-size: 10px;
  }
}

@media (min-width: 1920px) {
  .front-page-navbar .v-toolbar {
    max-inline-size: 1416px
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .front-page-navbar .v-toolbar {
    max-inline-size: 1168px
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .front-page-navbar .v-toolbar {
    max-inline-size: 868px
  }

  .nav-menu {
    gap: 2rem
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .front-page-navbar .v-toolbar {
    max-inline-size:calc(100% - 64px)
  }
}

@media (max-width: 600px) {
  .front-page-navbar .v-toolbar {
    max-inline-size:calc(100% - 32px)
  }
}

.front-page-navbar .v-toolbar {
  inset-inline: 0 !important;
  margin-inline: auto !important;
}
</style>