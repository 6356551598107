<template>
  <v-dialog :value="value" width="400" persistent :retain-focus="false">
    <v-card>
      <v-card-text class="py-4 px-4 d-flex align-start">
        <v-icon left color="error lighten-2">$mdi-alert-outline</v-icon>
        <div>
          <v-list-item-title>Are You Sure?</v-list-item-title>
          <p class="mt-2">{{ text }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', false)">Cancel</v-btn>
        <v-btn text color="error" depressed @click="$emit('confirmed')" :loading="loading">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'text', 'loading']
}
</script>