const {
  getVariableType
} = require('@/helper/utils')

module.exports = {
  uniqueValues: (arr) => {
    if (arr.length) {
      if (getVariableType(arr[0]) !== 'object') {
        return [... new Set(arr)]
      } else {
        const uniqueArray = Array.from(
          new Set(arr.map((item) => JSON.stringify(item)))
        ).map((item) => JSON.parse(item))

        return uniqueArray
      }
    } else {
      return []
    }
  },
  howManyInArray: (sourceArr, arr) => {
    return arr.filter(v => sourceArr.includes(v)).length
  },
  mergeArrayWithoutDuplicate: (targetArr, arr) => {
    targetArr.push.apply(targetArr, arr.filter((v) => targetArr.indexOf(v) < 0))
  },
  removeMultipleFromArray: (targetArr, arr) => {
    arr.forEach((v) => {
      const index = targetArr.indexOf(v)
      
      if (index > -1) {
        targetArr.splice(index, 1)
      }
    })
  },
  removeItemAtIndex: (arr, index) => {
    const v = [...arr]

    v.splice(index, 1)

    return v
  },
  includesAny: (arr, values) => values.some(v => arr.includes(v))
}