import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import auth from './modules/auth'
import user from './modules/user'
import comp from './modules/comp'
import property from './modules/property'
import setting from './modules/setting'
import notification from './modules/notification'
import plan from './modules/plan'
import contact from './modules/contact'
import address from './modules/address'
import dropdown from './modules/dropdown'
import zoning_and_overlay from './modules/zoning_and_overlay'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    route: null
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route
    }
  },
  getters: {
    salesType: (state) => {
      return state.route.query.sales_type
    },
    isSalesComp: (state) => {
      return state.route.query.sales_type === 'salesComp'
    },
    isLeaseComp: (state) => {
      return state.route.query.sales_type === 'leaseComp'
    }
  },
  modules: {
    app,
    auth,
    user,
    comp,
    property,
    setting,
    notification,
    plan,
    contact,
    address,
    dropdown,
    zoning_and_overlay
  }
})

export default store
