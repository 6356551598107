<template>
  <div>
    <v-form ref="form">
      <v-row dense>
        <v-col cols="12">
          <label class="caption required">Lease Type <span>*</span></label>
          <BtnSelection
            v-model="local.lease_type_id"
            ky="leaseTypes"
            mandatory
            grow
            @input="handleLeaseTypeChange"
          />
        </v-col>
      </v-row>
      <v-row dense class="mt-3">
        <v-col cols="7">
          <v-checkbox
            v-model="local.is_owner_occupied"
            label="This is Owner Occupied"
            hide-details
            class="ml-n1 mt-0 pt-0"
          />
        </v-col>
        <v-col cols="7">
          <v-checkbox
            v-model="local.is_sublease"
            label="This is a Sub Lease"
            hide-details
            class="ml-n1 mt-0 pt-0"
          />
        </v-col>
        <v-col cols="7">
          <v-checkbox
            v-model="local.is_partial"
            label="Occupies part of building premises"
            hide-details
            class="ml-n1 mt-0 pt-0"
            :disabled="!isMultiSale"
            @change="handlePartialChange"
          />
        </v-col>
        <v-col v-if="local.is_partial" cols="8">
          <label
            for="partial_building_area"
            class="caption required"
          >
            Building Area <span>*</span>
          </label>
          <div class="d-flex align-center gap-sm">
            <CurrencyText
              id="partial_building_area"
              v-model="local.partial_building_area"
              :rules="[$rules.required]"
              class="flex-grow-1"
              :units="dropdowns.areaUnits"
            />
            <DropdownSelect
              v-model="local.partial_building_area_type_id"
              ky="buildingAreaTypes"
              class="shrinked"
              :clearable="false"
            />
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="8">
          <div>
            <label for="commencing_rent" class="caption required">
              Commencing Rent / Face Rent
              <span>*</span>
            </label>
            <div class="d-flex gap-sm">
              <div class="flex-grow-1">
                <CurrencyText
                  id="commencing_rent"
                  v-model="local.commencing_rent"
                  :has-unit="false"
                  prefix="$"
                  hide-details
                  :rules="[$rules.required]"
                />
                <v-checkbox
                  v-model="local.is_commencing_rent_approx"
                  label="Approx"
                  hide-details
                  class="ml-n1 pt-0"
                />
              </div>
              <DropdownSelect
                v-model="local.commencing_rent_type_id"  
                ky="leaseAmountTypes"
                :clearable="false"
                class="shrinked"
                hide-details
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <div>
            <label for="lease_date" class="caption required">Lease Date (Commencement Date)<span>*</span></label>
            <DatePickerText
              id="lease_date"
              v-model="local.lease_dt"
              dense
              outlined
              hide-details
              :rules="[$rules.required, $rules.dateOrNullRule]"
            />
          </div>
          <v-checkbox
            v-model="local.is_lease_dt_approx"
            label="Approx"
            hide-details
            class="ml-n1 pt-0"
          />
        </v-col>
        <v-col cols="6">
          <label for="lease_terms" class="caption" :class="{'required': local.lease_type_id !== 3}">
            Lease Terms
            <span v-if="local.lease_type_id !== 3">*</span>
            <span v-else>(Optional)</span>
          </label>
          <CurrencyText
            id="lease_terms"
            v-model="local.lease_terms"
            hide-details
            :disabled="local.lease_type_id === 3"
            :rules="local.lease_type_id === 3 ? [] : [$rules.required]"
            :units="dropdowns.durationUnits"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <label for="lease_option" class="caption">Lease Option (Optional)</label>
          <div class="d-flex flex-wrap gap-sm">
            <div v-for="(option, i) in local.lease_options" :key="i" class="d-flex align-center mr-4">
              <CurrencyText
                v-model="local.lease_options[i]"
                hide-details
                :clearable="false"
                :has-unit="false"
                style="max-width: 68px;"
              />
              <div>
                <v-icon v-if="i !== local.lease_options.length - 1" right @click="handleRemoveLeaseOption(i)">$mdi-minus</v-icon>
                <v-icon v-else right @click="handleAddLeaseOption">$mdi-plus</v-icon>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <v-chip outlined label v-if="local.lease_options.length > 1 || (local.lease_options[0] !== null && local.lease_options[0] !== '')">
              <span v-for="(option, i) in local.lease_options" :key="i">
                {{ option }}<span v-if="i !== local.lease_options.length - 1 && local.lease_options[i + 1] !== null && local.lease_options[i + 1] !== ''" class="mx-1">+</span>
              </span>
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12">
          <label for="lease_option_special" class="caption">Lease Option Special (Optional)</label>
          <v-text-field
            v-model="local.lease_option_special"
            id="lease_option_special"
            outlined
            hide-details
            :disabled="local.lease_type_id === 3"
          />
        </v-col>
      </v-row>

      <v-row dense class="mt-3">
        <v-col cols="12">
          <label class="caption">Lease Increase (Optional)</label>
          <div class="d-flex gap-sm">
            <DropdownSelect
              v-model="local.lease_increase_type_id"
              ky="leaseIncreaseTypes"
              class="shrinked"
              :clearable="false"
              hide-details
              :disabled="local.lease_type_id === 3"
              @input="update('lease_increase', null)"
            />
            <CurrencyText
              v-if="local.lease_increase_type_id === 1"  
              v-model="local.lease_increase"
              :has-unit="false"
              suffix="%"
              hide-details
              class="flex-grow-1"
              :disabled="local.lease_type_id === 3"
            />
            <v-textarea
              v-else-if="local.lease_increase_type_id === 3"
              v-model="local.lease_increase"
              outlined
              :clearable="false"
              rows="3"
              hide-details
              :disabled="local.lease_type_id === 3"
            />
          </div>
        </v-col>
      </v-row>
      
      <v-row dense class="mt-3">
        <v-col cols="6">
          <v-row dense>
            <v-col cols="12">
              <label for="lease_review" class="caption">Lease Review (Optional)</label>
              <v-textarea
                v-model="local.lease_review"
                id="lease_review"
                rows="2"
                outlined
                hide-details
                :clearable="false"
                :disabled="local.lease_type_id === 3"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="local.is_market_review_at_option"
                label="Market review at option"
                hide-details
                class="ml-n1 pt-0"
                :disabled="local.lease_type_id === 3"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <ExpandPanel label="Income & Outgoings" class="mt-3" content-class="pl-3">
        <v-row dense>
          <v-col cols="8">
            <label for="expense_structure_id" class="caption">
              Expense Structure
            </label>
            <DropdownSelect
              id="expense_structure_id"
              v-model="local.expense_structure_id"
              ky="expenseStructures"
              :clearable="false"
              hide-details
            ></DropdownSelect>
          </v-col>
          <v-col cols="4">
            <label for="expense_structure_percent" class="caption">%</label>
            <CurrencyText
              id="expense_structure_percent"
              v-model="local.expense_structure_percent"
              :has-unit="false"
              suffix="%"
              :with-precision="false"
              hide-details
            />
          </v-col>
          <!-- TODO: Need to make this null if changed to other -->
          <v-col v-if="local.expense_structure_id === 5" cols="12">
            <label for="custom_expense_structure" class="caption">Custom Expense Structure</label>
            <v-text-field
              id="custom_expense_structure"
              v-model="local.custom_expense_structure"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mt-3">
          <v-col cols="6">
            <label for="recoverable_outgoings" class="caption">
              Recoverable Outgoings
            </label>
            <CurrencyText
              id="recoverable_outgoings"
              v-model="local.recoverable_outgoings"
              :has-unit="false"
              prefix="$"
              hide-details
            />
            <div class="d-flex ml-n1 mt-n1">
              <v-checkbox
                v-model="local.is_recoverable_outgoings_approx"
                label="Approx"
                hide-details
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <label for="irrecoverable_outgoings" class="caption">
              Nonrecoverable Outgoings
            </label>
            <CurrencyText
              id="irrecoverable_outgoings"
              v-model="local.irrecoverable_outgoings"
              :has-unit="false"
              prefix="$"
              hide-details
            />
            <div class="d-flex ml-n1 mt-n1">
              <v-checkbox
                v-model="local.is_irrecoverable_outgoings_approx"
                label="Approx"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="6">
            <label for="total_outgoings" class="caption">
              Total Outgoings
            </label>
            <CurrencyText
              id="total_outgoings"
              v-model="local.total_outgoings"
              :has-unit="false"
              prefix="$"
              hide-details
            />
            <div class="d-flex ml-n1 mt-n1">
              <v-checkbox
                v-model="local.is_total_outgoings_approx"
                label="Approx"
                hide-details
              />
            </div>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="local.is_rla_applies"
              label="RLA Applies"
              hide-details
              class="ml-n1 pt-0"
            />
          </v-col>
        </v-row>
      </ExpandPanel>

      <ExpandPanel label="Rent Incentives" class="mt-3" content-class="pl-3">
        <v-row dense class="mt-2">
          <v-col cols="10">
            <div v-for="i in 3" :key="i" class="d-flex gap-sm mb-2">
              <DropdownSelect
                v-model="local[`rent_incentive_${i}_type_id`]"
                ky="leaseIncentiveTypes"
                class="shrinked"
                :clearable="false"
                hide-details
                :disabled="local.lease_type_id === 3"
                @input="update(`rent_incentives_${i}`, null)"
              ></DropdownSelect>
              <div v-if="local[`rent_incentive_${i}_type_id`] === 1" class="d-flex gap-sm flex-grow-1">
                <CurrencyText
                  v-model="local[`rent_incentives_${i}`]"
                  :has-unit="false"
                  :disabled="local.lease_type_id === 3"
                  hide-details
                  class="flex-grow-1"
                />
                <DropdownSelect
                  v-model="local[`rent_incentive_${i}_unit_id`]"
                  ky="periodTypes"
                  class="shrinked"
                  :clearable="false"
                  hide-details
                  :disabled="local.lease_type_id === 3"
                />
              </div>
              <div v-else-if="local[`rent_incentive_${i}_type_id`] === 3" class="d-flex gap-sm flex-grow-1">
                <CurrencyText
                  v-model="local[`rent_incentives_${i}`]"
                  prefix="%"
                  :has-unit="false"
                  hide-details
                  :disabled="local.lease_type_id === 3"
                />
                <DropdownSelect
                  v-model="local[`rent_incentive_${i}_unit_id`]"
                  ky="leaseAmountTypes"
                  class="shrinked"
                  :clearable="false"
                  hide-details
                  :disabled="local.lease_type_id === 3"
                />
              </div>
              <CurrencyText
                v-else-if="local[`rent_incentive_${i}_type_id`] === 2"
                v-model="local[`rent_incentives_${i}`]"
                prefix="$"
                :has-unit="false"
                hide-details
                :disabled="local.lease_type_id === 3"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <label for="special_arrangement_details" class="caption">Special Arrangement Details (Optional)</label>
            <v-textarea
              id="special_arrangement_details"
              v-model="local.special_arrangement_details"
              outlined
              :clearable="false"
              rows="2"
              hide-details
            />
          </v-col>
        </v-row>
      </ExpandPanel>

      <ExpandPanel label="Add Tenant(s)" class="mt-3" content-class="pl-3">
        <v-row dense>
          <v-col cols="12">
            <div>
              <label for="tenant_ids">Tenants</label>
              <v-autocomplete
                id="tenant_ids"
                v-model="local.tenant_ids"
                :items="tenants"
                :item-text="(item) => item.is_company ? `${item.company_name} (Company)` : `${item.full_name} (Individual)`"
                item-value="id"
                chips
                small-chips
                outlined
                multiple
                clearable
                hide-details
                prepend-inner-icon="$mdi-magnify"
              >
              </v-autocomplete>
              <div class="text-right">
                <span
                  class="primary--text text-decoration-underline cursor-pointer caption"
                  @click="handleOpenContactModal('Tenant')"
                >
                  Create a new tenant
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>

      <v-row dense class="mt-2">
        <v-col cols="12">
          <label for="analysis_comments" class="caption">Lease Analysis Comments</label>
          <v-textarea
            id="analysis_comments"
            v-model="local.analysis_comments"
            outlined
            hide-details
            :clearable="false"
            rows="3"
          />
        </v-col>
        <v-col cols="12">
          <label for="description" class="caption required">Lease Description / Commentary <span>*</span></label>
          <v-textarea
            id="description"
            v-model="local.description"
            outlined
            :clearable="false"
            rows="3"
            hide-details
            :rules="[$rules.required]"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      v-model="contactModal"
      width="600"
      scrollable
      persistent
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
          Create New {{ contactForm.main_type }}
        </v-card-title>
        <v-card-text class="mt-3 px-4">
          <ContactForm
            ref="contactForm"
            v-model="contactForm"
            @close="contactModal = false"
            @completed="handleContactCreated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  defaultContactForm
} from '@/@propedia/default_forms'
import ContactForm from '@/components/forms/ContactForm'

export default {
  props: [
    'value',
    'isSalesLease',
    'isMultiSale',
    'isSingleTenant'
  ],
  components: {
    ContactForm
  },
  data() {
    return {
      contactModal: false,
      contactForm: structuredClone({
        main_type: null,
        ...defaultContactForm
      }),
      tenants: []
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapState('contact', ['loadingContacts']),
    local() {
      return this.value
    }
  },

  mounted() {
    this._getContacts()
  },

  methods: {
    ...mapActions('contact', ['getContacts']),
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    handlePartialChange(v) {
      if (!v) {
        this.$emit('input', {
          ...this.local,
          partial_building_area: {
            value: null,
            unit: 'SQM'
          },
          partial_building_area_type_id: 1
        })
      }
    },
    handleLeaseTypeChange(v) {
      if (v === 3) {
        this.$emit('input', {
          ...this.local,
          lease_terms: {
            value: null,
            unit: 'Years'
          },
          lease_increase: null,
          lease_increase_type_id: 1,
          lease_option: [],
          lease_option_special: null,
          lease_review: null,
          is_market_review_at_option: null,
          rent_incentive_1_type_id: 1,
          rent_incentives_1: null,
          rent_incentive_1_unit_id: 1,
          rent_incentive_2_type_id: 1,
          rent_incentives_2: null,
          rent_incentive_2_unit_id: 1,
          rent_incentive_3_type_id: 1,
          rent_incentives_3: null,
          rent_incentive_3_unit_id: 1
        })
      }
    },
    async _getContacts() {
      this.tenants = await this.getContacts({
        main_types: ['Tenant']
      })
    },
    handleOpenContactModal(main_type) {
      this.contactForm = structuredClone({
        ...defaultContactForm,
        main_type
      }),
      this.contactModal = true
    },
    async handleContactCreated(id) {
      await this._getContacts()

      this.update('tenant_ids', [...this.local.tenant_ids, id])

      this.contactModal = false
    },
    handleAddLeaseOption() {
      this.update('lease_options', [
        ...this.local.lease_options, null
      ])
    },
    handleRemoveLeaseOption(i) {
      this.update('lease_options', this.local.lease_options.filter((_, j) => i !== j))
    }
  }
}
</script>