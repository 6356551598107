import api from '@/api'

export default {
  namespaced: true,

  state: {
    loadingContacts: false,
    contacts: []
  },
  mutations: {
    SET_LOADING_CONTACTS(state, loading) { state.loadingContacts = loading },
    SET_CONTACTS(state, contacts) { state.contacts = contacts }
  },
  actions: {
    createContact: async (_, payload) => {
      return api.post('contacts', payload)
    },

    getContact: async (_, id) => {
      return api.get(`contacts/${id}`)
    },

    getContacts: async ({ commit }, { main_types, is_company }) => {
      try {
        commit('SET_LOADING_CONTACTS', true)

        const res = await api.get('contacts', {
          params: {
            main_types,
            is_company
          }
        })

        commit('SET_CONTACTS', res.data.contacts)

        return res.data.contacts
      } finally {
        commit('SET_LOADING_CONTACTS', false)
      }
    },

    getContactsByIds: (_, ids) => {
      return api.get('contacts/by_ids', {
        params: {
          ids
        }
      })
    }
  }
}