module.exports = {
  defaultRatingForm: {
    rating_scope_id: 1,
    rating_type_id: 1,
    rating: null
  },
  defaultAreaForm: {
    area_1_type_id: null,
    area_1: {
      value: null,
      unit: 'SQM'
    },
    area_2_type_id: null,
    area_2: {
      value: null,
      unit: 'SQM'
    },
    area_3_type_id: null,
    area_3: {
      value: null,
      unit: 'SQM'
    },
    area_4_type_id: null,
    area_4: {
      value: null,
      unit: 'SQM'
    },
    area_5_type_id: null,
    area_5: {
      value: null,
      unit: 'SQM'
    },
    area_6_type_id: null,
    area_6: {
      value: null,
      unit: 'SQM'
    },
    area_7_type_id: null,
    area_7: {
      value: null,
      unit: 'SQM'
    }
  },
  defaultSalesForm: {
    address_label: null,
    is_multi_sale: false,
    is_offmarket: false,
    sale_price: null,
    sale_dt: null,
    proportional_land_value: null,
    proportional_building_value: null,
    sales_terms: null,
    gst_included: false,
    settlement_term_type_id: 1,
    settlement_term_details: [
      [null, null],
      [null, null],
      [null, null],
      [null, null],
      [null, null],
      [null, null]
    ],
    settlement_term_commentary: null,
    sale_status_id: 5,
    ownership_transfer: 100,
    purchaser_ids: [],
    vendor_ids: [],
    tenure_type_id: 1,
    passing_rent: null,
    passing_rent_type_id: 1,
    is_passing_rent_approx: false,
    analysis_comments: null,
    description: null,
    is_single_tenant: null,
    is_add_lease: false,
    is_owner_occupied: null,
    is_sublease: null,
    expense_structure_id: null,
    expense_structure_percent: null,
    custom_expense_structure: null,
    recoverable_outgoings: null,
    is_recoverable_outgoings_approx: false,
    irrecoverable_outgoings: null,
    is_irrecoverable_outgoings_approx: false,
    total_outgoings: null,
    is_total_outgoings_approx: false,
    is_rla_applies: false,
    leases: [],
    tenant_ids: [],
    market_rent: null,
    market_rent_type_id: 1,
    market_recoverable_outgoings: null,
    market_irrecoverable_outgoings: null,
    market_total_outgoings: null,
    market_adjustment: null,
    market_adjustment_comment: null,
    market_wale: null,
    market_irr: null,
    reversionary_field: null,
    initial_yield_fully_let: null,
    extra_info: null,
    images: [],
    docs: []
  },
  defaultLeaseForm: {
    address_label_prefix: null,
    address_label_id: null,
    address_label: null,
    is_multi_sale: false,
    lease_type_id: 1,
    is_owner_occupied: false,
    is_sublease: false,
    is_partial: false,
    partial_building_area: {
      value: null,
      unit: 'SQM'
    },
    partial_building_area_type_id: 1,
    commencing_rent: null,
    commencing_rent_type_id: 1,
    is_commencing_rent_approx: false,
    lease_dt: null,
    is_lease_dt_approx: false,
    lease_terms: {
      value: null,
      unit: 'Years'
    },
    lease_increase_type_id: 1,
    lease_increase: null,
    lease_options: [null],
    lease_option_special: null,
    lease_review: null,
    is_market_review_at_option: false,
    rent_incentive_1_type_id: 1,
    rent_incentives_1: null,
    rent_incentive_1_unit_id: 1,
    rent_incentive_2_type_id: 1,
    rent_incentives_2: null,
    rent_incentive_2_unit_id: 1,
    rent_incentive_3_type_id: 1,
    rent_incentives_3: null,
    rent_incentive_3_unit_id: 1,
    special_arrangement_details: null,
    description: null,
    analysis_comments: null,
    expense_structure_id: null,
    expense_structure_percent: null,
    custom_expense_structure: null,
    recoverable_outgoings: null,
    is_recoverable_outgoings_approx: false,
    irrecoverable_outgoings: null,
    is_irrecoverable_outgoings_approx: false,
    total_outgoings: null,
    is_total_outgoings_approx: false,
    is_rla_applies: null,
    tenant_ids: [],
    market_rent: null,
    market_rent_type_id: 1,
    market_recoverable_outgoings: null,
    market_irrecoverable_outgoings: null,
    market_total_outgoings: null,
    market_adjustment: null,
    market_adjustment_comment: null,
    extra_info: null,
    images: [],
    docs: []
  },
  defaultBuildingForm: {
    type_ids: [],
    improvement_status_id: 1,
    with_permit_approved: false,
    existing_rights_of_transfer_id: 1,
    zone_codes: [],
    overlay_codes: [],
    volume_folios: [
      {
        volume: null,
        folio: null
      }
    ],
    lot_section_plans: [
      {
        lot: null,
        section: null,
        plan: null
      }
    ],
    is_crownland: false,
    building_area: {
      value: null,
      unit: 'SQM'
    },
    building_area_type_id: 1,
    building_grade_id: 0,
    number_of_childcare_places: null,
    number_of_rooms: null,
    building_name: null,
    footprint_area: {
      value: null,
      unit: 'SQM'
    },
    awning_height: null,
    parking_type_ids: [],
    number_of_car_spaces: null,
    year_built: null,
    year_rennovated: null,
    number_of_floors: null,
    number_of_basement_floors: null,
    building_status_id: null,
    building_coverage_status_id: null,
    building_level: null,
    building_level_coverage_status_id: null,
    ratings: [],

    land_area: {
      value: null,
      unit: 'SQM'
    },
    is_easement: false,
    hardstand_area: {
      value: null,
      unit: 'SQM'
    },
    developable_area: {
      value: null,
      unit: 'SQM'
    },
    land_shape_id: 1,
    number_of_street_frontages: 0,
    street_1_length: null,
    street_1_name: null,
    street_1_name_type_id: 1,
    street_2_length: null,
    street_2_name: null,
    street_2_name_type_id: 1,
    street_3_length: null,
    street_3_name: null,
    street_3_name_type_id: 1,
    street_4_length: null,
    street_4_name: null,
    street_4_name_type_id: 1,
    street_5_length: null,
    street_5_name: null,
    street_5_name_type_id: 1,
    street_6_length: null,
    street_6_name: null,
    street_6_name_type_id: 1,
    permit_approved_status_id: null,
    add_permit: false,
    number_of_approved_childcare_places: null,
    number_of_approved_rooms: null,
    total_sellable_area: {
      value: null,
      unit: 'SQM'
    },
    permit_application_number: null,
    gross_floor_area: {
      value: null,
      unit: 'SQM'
    },
    building_height_meters: null,
    existing_use_type_ids: []
  },
  defaultCampaignForm: {
    sale_method_id: null,
    current_status_id: null,
    is_foreclosed: false,
    market_start_dt: null,
    market_end_dt: null,
    is_conjunction_sale: false,
    agency1_id: null,
    agency2_id: null,
    ads: {
      ad1_url: null,
      ad1_images: [],
      ad2_url: null,
      ad2_images: [],
      ad3_url: null,
      ad3_images: []
    }
  },
  defaultSourceForm: {
    source_type_id: null,
    source_subtype_id: null,
    contact_id: null,
    publication_dt: null,
    valuation_report_number: null,
    extra_info: null,
    url: null,
    images: [],
    reviewer_ids: []
  },
  defaultAddressForm: {
    lot_number_prefix: null,
    lot_number: null,
    lot_number_suffix: null,

    flat_type: null,
    flat_number_prefix: null,
    flat_number: null,
    flat_number_suffix: null,
    level_type: null,
    level_number_prefix: null,
    level_number: null,
    level_number_suffix: null,

    number_first_prefix: null,
    number_first: null,
    number_first_suffix: null,
    number_last_prefix: null,
    number_last: null,
    number_last_suffix: null,
    street_name: null,
    street_type_code: null,
    street_suffix_code: null,
    postcode: null,
    state_abbreviation: null,
    locality_name: null,

    latitude: null,
    longitude: null,
    gnaf_property_pid: null
  },
  defaultContactForm: {
    is_company: false,
    main_type: null,

    company_name: null,
    business_type_id: null,
    company_website: null,
    office_address_detail_pid: null,
    office_phone: null,
    office_email: null,
    
    full_name: null,
    ownership_type_id: null,
    is_overseas: null,
    contact_company_id: null,
    position: null,
    division: null,
    mobile: null,
    email: null,
    comments: null
  },
  defaultDwellingForm: {
    product_type_id: null,
    yield_count: null,
    min_area: {
      value: null,
      unit: 'SQM'
    },
    max_area: {
      value: null,
      unit: 'SQM'
    },
    average_area: {
      value: null,
      unit: 'SQM'
    },
    config_bed: null,
    config_bath: null,
    config_car: null
  }
}