<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="0"
        class="rounded-0 px-4 py-2 property-card"
        :ripple="false"
        :color="hover || selected ? 'grey lighten-3' : ''"
        @mouseenter="hoverComp(item.geom.coordinates)"
        @mouseleave="hoverComp(null)"
      >
        <v-menu
          right
          offset-x
          nudge-right="6px"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              icon
              absolute
              style="top: 6px; right: 12px;"
            >
              <v-icon>$mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense nav>
            <v-list-item @click="handleUserShortlistShow">
              <v-list-item-icon><v-icon>$mdi-heart-plus</v-icon></v-list-item-icon>
              <v-list-item-title>Add to shortlist</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editComp">
              <v-list-item-icon><v-icon>$mdi-pencil</v-icon></v-list-item-icon>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item
              :href="`/property-details/${item?.id}?sales_type=${salesType}`"
              target="_blank"
            >
              <v-list-item-icon><v-icon>$mdi-open-in-new</v-icon></v-list-item-icon>
              <v-list-item-title>View</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-show="hover || selected" class="primary property-selector"></div>
        <div class="font-weight-bold mr-8 mb-1">
          <div v-for="(address_detail_pid, i) in Object.keys(item.full_address)" :key="i">
            {{ item.full_address[address_detail_pid] }}
          </div>
        </div>
        <div class="mb-1">
          <PropertyTypeField
            :type-ids="item.type_ids"
            :unlocked="item.unlocked"
          />
        </div>

        <v-row v-if="salesType === 'salesComp'">
          <v-col>
            <NormalField
              label="Sale Price (excl. GST)"
              type="price"
              :value="item.sale_price_gst_excl"
              :unlocked="item.unlocked"
            />
          </v-col>
          <v-col>
            <NormalField
              label="Sale Date"
              :value="item.sale_dt"
              type="date-format"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="salesType === 'leaseComp'">
          <v-col>
            <NormalField
              label="Commencing Rent"
              type="price"
              :value="item.commencing_rent"
            />
          </v-col>
          <v-col>
            <NormalField
              label="Lease Date"
              :value="item.lease_dt"
              type="date-format"
            />
          </v-col>
        </v-row>

        <div class="d-flex mt-2 align-center gap-sm">
          <v-btn
            depressed
            small
            class="flex-grow-1"
            :color="hover || selected ? 'grey lighten-1' : 'grey lighten-3'"
            @click="eventBus.$emit('detail-btn-clicked', item)"
          >
            View Details
          </v-btn>
        </div>
      </v-card>
    </v-hover>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { bus } from '@/plugins/bus'

export default {
  props: ['item', 'salesType'],

  data() {
    return {
      eventBus: bus,
    }
  },

  computed: {
    ...mapState('property', ['selectedComp']),
    selected() {
      return this.item.id == this.selectedComp?.id
    }
  },

  methods: {
    ...mapActions('property', [
      'selectComp',
      'hoverComp'
    ]),
    ...mapMutations('property', [
      'SET_EDIT_MODAL', 'SET_COMPID_FOR_EDIT',
      'SET_USER_SHORTLIST_MODAL', 'SET_COMPID_FOR_SHORTLIST'
    ]),
    handleUserShortlistShow() {
      this.SET_COMPID_FOR_SHORTLIST(this.item.id)
      this.SET_USER_SHORTLIST_MODAL(true)
    },
    editComp() {
      this.SET_COMPID_FOR_EDIT(this.item.id)
      this.SET_EDIT_MODAL(true)
    }
  }
}
</script>

<style scoped lang="scss">
.property-selector {
  position: absolute;
  width: 4px;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  border-radius: 0;
}
.shortlist-abs-btn {
  margin-top: 26px;
}
</style>