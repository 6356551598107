<template>
  <v-radio-group
    :value="local.value"
    class="mt-0"
    hide-details
    @change="update('value', $event)"
  >
    <v-radio
      v-for="(option, i) in options"
      :key="i"
      :label="option.title"
      :value="option.value"
    ></v-radio>
    <div class="d-flex">
      <v-radio
        value="custom"
        class="mb-1"
      />
      <v-row dense>
        <v-col>
          <CurrencyText
            v-model="local.from_price"
            prefix="$"
            :has-unit="false"
            hide-details
            bouncing-time="500"
            @debounced="update('from_price', $event)"
          />
        </v-col>
        <v-col>
          <CurrencyText
            v-model="local.to_price"
            prefix="$"
            :has-unit="false"
            hide-details
            bouncing-time="500"
            @debounced="update('to_price', $event)"
          />
        </v-col>
      </v-row>
    </div>
  </v-radio-group>
</template>

<script>
import options from '@/@propedia/constants/options'

export default {
  props: ['value'],

  data() {
    return {
      options: options.filter.salePrice
    }
  },

  computed: {
    local() {
      return this.value ?? {
        value: 'all',
        from_price: '',
        to_price: ''
      }
    }
  },

  methods: {
    update(key, value) {
      if (key === 'value' && value !== 'custom') {
        this.$emit('input', {
          value: value,
          from_price: null,
          to_price: null
        })
      } else {
        this.$emit('input', {
          ...this.local,
          value: 'custom',
          [key]: value
        })
      }
    }
  }
}
</script>