<template>
  <div>
    <a @click="expanded = !expanded" class="userselect-none">
      <span v-if="expanded">
        {{ label }} <v-icon color="primary" small>$mdi-chevron-up</v-icon>
      </span>
      <span v-else>
        {{ label }} <v-icon color="primary" small>$mdi-chevron-down</v-icon>
      </span>
    </a>
    <v-expand-transition>
      <div v-show="expanded" :class="contentClass">
        <div class="py-2">
          <slot />
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    contentClass: {
      type: String
    }
  },
  data() {
    return {
      expanded: false
    }
  }
}
</script>