<template>
  <v-form>
    <v-row dense>
      <v-col cols="6">
        <label class="caption">Zone</label>
        <ZoneOverlayMultiSelector
          v-model="local.zone_codes"
          type="zone"
          :loading="loadingZone"
        />
      </v-col>
      <v-col cols="6">
        <label class="caption">Overlay</label>
        <ZoneOverlayMultiSelector
          v-model="local.overlay_codes"
          type="overlay"
          :loading="loadingOverlay"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="d-flex mb-n2">
        <div class="caption" style="width: 188px;">Volume</div>
        <div class="caption" style="width: 160px;">Folio</div>
      </v-col>
      <v-col
        v-for="(volume_folio, i) in value.volume_folios"
        :key="i"
        cols="12"
        class="d-flex align-center"
      >
        <div style="max-width: 160px;">
          <v-text-field
            v-model="volume_folio.volume"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <span style="
          margin-left: 10px;
          margin-right: 10px;
          font-size: 20px;
        ">/</span>
        <div style="max-width: 160px;">
          <v-text-field
            v-model="volume_folio.folio"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <div class="ml-2 d-flex gap-md">
          <v-icon v-if="i < value.volume_folios.length - 1" @click="removeVolumeFolio()">$mdi-trash-can</v-icon>
          <v-icon v-if="i === value.volume_folios.length - 1" @click="handleAddVolumeFolio()">$mdi-plus</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="d-flex mb-n2">
        <div class="caption" style="width: 148px;">Lot</div>
        <div class="caption" style="width: 166px;">Section</div>
        <div class="caption" style="width: 160px;">Plan</div>
      </v-col>
      <v-col
        v-for="(lot_section_plan, i) in local.lot_section_plans"
        :key="i"
        cols="12"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="lot_section_plan.lot"
          outlined
          hide-details
          style="max-width: 120px;"
        ></v-text-field>
        <span style="
          margin-left: 10px;
          margin-right: 10px;
          font-size: 20px;
        ">/</span>
        <v-text-field
          v-model="lot_section_plan.section"
          outlined
          hide-details
          style="max-width: 140px;"
        ></v-text-field>
        <span style="
          margin-left: 10px;
          margin-right: 10px;
          font-size: 20px;
        ">/</span>
        <v-text-field
          v-model="lot_section_plan.plan"
          outlined
          hide-details
          style="max-width: 160px;"
        ></v-text-field>

        <div class="ml-2 d-flex gap-md">
          <v-icon v-if="i < value.lot_section_plans.length - 1" @click="removeLotSectionPlan()">$mdi-trash-can</v-icon>
          <v-icon v-if="i === value.lot_section_plans.length - 1" @click="handleAddLotSectionPlan()">$mdi-plus</v-icon>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// import { mapState, mapGetters, mapActions } from 'vuex'
import arrayHelper from '@/helper/array'

import ZoneOverlayMultiSelector from '@/components/inputs/ZoneOverlayMultiSelector'

export default {
  props: [
    'value'
  ],
  
  components: {
    ZoneOverlayMultiSelector
  },

  data() {
    return {
      loadingZone: false,
      loadingOverlay: false
    }
  },

  computed: {
    local() {
      return this.value
    }
    // ...mapState('dropdown', ['dropdowns']),
    // ...mapGetters('dropdown', ['isDevelopmentSiteSelected'])
  },

  methods: {
    // ...mapActions('property', ['getCompsFromAddressDetailPid']),
    handleAddVolumeFolio(address_detail_pid = null) {
      if (!address_detail_pid) { // Single address sale
        this.$emit('input', {
          ...this.value,
          volume_folios: [
            ...this.value.volume_folios,
            {
              volume: null,
              folio: null
            }
          ]
        })
      }
    },
    removeVolumeFolio(i) {
      this.$emit('input', {
        ...this.value,
        volume_folios: arrayHelper.removeItemAtIndex(this.value.volume_folios, i)
      })
    },
    handleAddLotSectionPlan(address_detail_pid = null) {
      if (!address_detail_pid) { // Single address sale
        this.$emit('input', {
          ...this.value,
          lot_section_plans: [
            ...this.value.lot_section_plans,
            {
              lot: null,
              section: null,
              plan: null
            }
          ]
        })
      }
    },
    removeLotSectionPlan(i) {
      this.$emit('input', {
        ...this.value,
        lot_section_plans: arrayHelper.removeItemAtIndex(this.value.lot_section_plans, i)
      })
    },
  }
}
</script>